import React from "react";
import { RadioGroupContext } from "./useRadioGroup";
import "./RadioGroup.css";

const RadioGroup: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({ children, ...props }) => {
  return (
    <RadioGroupContext.Provider value={props}>
      <div role="radiogroup" className="radio-group">
        {children}
      </div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
