import React from "react";

const Match: React.FC<{
  fallback?: JSX.Element;
}> = ({ children, fallback }) => {
  const childrens = React.Children.toArray(children) as React.ReactElement<{ when: boolean; children: JSX.Element }>[];

  const matchedElement = childrens.find((child) => child.props.when);

  return <>{matchedElement ? matchedElement.props.children : fallback}</>;
};

export const Case: React.FC<{ when: boolean }> = () => {
  return null;
};

export default Match;
