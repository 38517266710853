import Show from "@atoms/Show/Show";
import { FeatureFlags } from "@domain/environment/Environment";
import { Promocode, isShopPromoCode } from "@domain/promocode/Promocode";
import { useEnvironment } from "@hooks/useEnvironment";
import { Badge, BadgeVariant, Checkbox, CheckboxState, Link, Text } from "@lookiero/react-ui-kit";
import Pagination from "@molecules/Pagination/Pagination";
import Table from "@molecules/Table/Table";
import { emptyFn } from "@utils/emptyFn";

import { useAuth } from "@hooks/useAuth";
import { FIELD } from "@promocodes/components/PromocodeForm/PromocodeForm.schema";
import { PromoCodeTableElement } from "@promocodes/pages/Promocodes/Promocodes";
import { ROUTES } from "ui/router";
import "./PromocodeList.css";
import TEXT from "./Promocodes.intl.json";

type PromocodeListProps = {
  promocodes: PromoCodeTableElement[];
  page: number;
  itemsInPage: number;
  onChangePage: (page: number) => void;
  totalItems: number;
  onClickPromocode: (id: string) => void;
};

const tableHead = [
  { key: "name", content: "Promotion Name" },
  { key: FIELD.AMOUNT_EUR, content: "Amount EUR" },
  { key: FIELD.AMOUNT_GBP, content: "Amount GBP" },
  { key: FIELD.AMOUNT_SEK, content: "Amount SEK" },
  { key: FIELD.AMOUNT_CHF, content: "Amount CHF" },
  { key: "discountPct", content: "Dto. PCT" },
  { key: "discountServicePct", content: "Dto. Service PCT" },
  { key: "isActive", content: "State" },
  { key: "firstOrderOnly", content: "First Order Only" },
  { key: "currentUses", content: "Current Uses" },
  { key: "actions", content: "" },
];

const todayIsAfterDate = (date: string) => {
  const today = new Date();
  return today.getTime() > new Date(date).getTime();
};

const renderRow = (featureFlags: FeatureFlags, canEdit: boolean) => ({
  isActive: ({ isActive }: { isActive: boolean }) => (
    <Badge variant={isActive ? BadgeVariant.GREEN : BadgeVariant.BASIC}>
      {isActive ? TEXT.ACTIVE_PROMOCODE : TEXT.INACTIVE_PROMOCODE}
    </Badge>
  ),
  firstOrderOnly: ({ firstOrderOnly }: { firstOrderOnly: boolean }) => (
    <Checkbox onChange={emptyFn} state={firstOrderOnly ? CheckboxState.CHECKED : CheckboxState.DEFAULT} disabled />
  ),
  actions: ({ key, endDate, services }: { key: number; endDate: string; services: string[] }) => {
    const promoHasFinished = todayIsAfterDate(endDate);
    const promoIsNotEditable = !featureFlags.SHOP_PROMOS && isShopPromoCode({ services } as Promocode);

    if (!canEdit) return null;
    if (promoHasFinished || promoIsNotEditable) return null;

    return (
      <Link
        to={ROUTES.PROMOCODES_EDIT.path.replace(":promocodeKey", key.toString())}
        onClick={(e) => e.stopPropagation()}
      >
        <Text>{TEXT.EDIT}</Text>
      </Link>
    );
  },
});

const PromocodeList: React.FC<PromocodeListProps> = ({
  promocodes,
  page,
  itemsInPage,
  onChangePage,
  totalItems,
  onClickPromocode,
}) => {
  const { featureFlags } = useEnvironment();
  const { hasPromocodesWriteRole } = useAuth();
  const editAuthorized = hasPromocodesWriteRole();

  return (
    <Table
      head={tableHead}
      renderRow={renderRow(featureFlags, editAuthorized)}
      rows={promocodes}
      onClick={(data) => onClickPromocode(data.key)}
    >
      <Show when={totalItems > itemsInPage}>
        <Pagination page={page} itemsInPage={itemsInPage} totalItems={totalItems} onChangePage={onChangePage} />
      </Show>
    </Table>
  );
};
export default PromocodeList;
