import { PrimitivePromocode, Promocode, isShopPromoCode, DiscountAmounts } from "@domain/promocode";
import { arrayStringToBooleanObject } from "@utils/arrayStringToBooleanObject";
import { toFrequencyObject } from "@domain/promocode/PromocodeFrequency";
import {
  PROMOCODE_CREATION_MODES,
  PROMOCODE_CREATION_TYPES,
} from "@promocodes/components/PromocodeForm/PromocodeForm.schema";
import { PrimitiveBulk } from "@domain/promocode/Bulk";
import { FeatureFlags } from "@domain/environment/Environment";
import { FormFields } from "../PromocodeForm";
import { EURO_SITES } from "@domain/promocode/PromocodeSites";

export const isShopForm = (values: FormFields): boolean => {
  return values.type === PROMOCODE_CREATION_TYPES.shop;
};

export const isBoxForm = (values: FormFields): boolean => {
  return values.type === PROMOCODE_CREATION_TYPES.box;
};

export const promocodeToFormValues = (promocode: Promocode): FormFields => ({
  name: promocode.name,
  description: promocode.description,
  type: isShopPromoCode(promocode) ? PROMOCODE_CREATION_TYPES.shop : PROMOCODE_CREATION_TYPES.box,
  mode: PROMOCODE_CREATION_MODES.single,
  numberOfPromocodes: "",
  promocodePrefix: "",
  promocodeLength: "",
  discountAmountEUR: promocode.discountAmounts.EUR?.toString(),
  discountAmountGBP: promocode.discountAmounts.GBP?.toString(),
  discountAmountSEK: promocode.discountAmounts.SEK?.toString(),
  discountAmountCHF: promocode.discountAmounts.CHF?.toString(),
  discountPct: promocode.discountPct.toString(),
  discountServicePct: promocode.discountServicePct.toString(),
  startDate: promocode.startDate,
  endDate: promocode.endDate,
  sites: arrayStringToBooleanObject(promocode.sites),
  applicableOrders: promocode.applicableOrders ? promocode.applicableOrders.toString() : "",
  totalUses: promocode.totalUses ? promocode.totalUses.toString() : "",
  firstOrderOnly: promocode.firstOrderOnly ?? false,
  isActive: promocode.isActive ?? false,
  addGift: promocode.addGift ?? false,
  frequency: toFrequencyObject(promocode.frequency),
});

const getDiscountAmounts = (values: FormFields): DiscountAmounts => {
  const sites = values.sites;
  const euroAvailable = EURO_SITES.some((e) =>
    Object.entries(sites).some(([site, selected]) => selected && site === e),
  );
  const amounts: DiscountAmounts = {};
  if (euroAvailable && values.discountAmountEUR) {
    amounts["EUR"] = parseInt(values.discountAmountEUR, 10);
  }
  if (sites["GB"] && values.discountAmountGBP) {
    amounts["GBP"] = parseInt(values.discountAmountGBP, 10);
  }
  if (sites["SE"] && values.discountAmountSEK) {
    amounts["SEK"] = parseInt(values.discountAmountSEK, 10);
  }
  if (sites["CH"] && values.discountAmountCHF) {
    amounts["CHF"] = parseInt(values.discountAmountCHF, 10);
  }
  return amounts;
};

export const formValuesToPrimitivePromocode = (values: FormFields, featureFlags: FeatureFlags): PrimitivePromocode => ({
  name: values.name,
  description: values.description,
  startDate: values.startDate,
  endDate: values.endDate,
  sites: values.sites,
  addGift: values.addGift,
  isActive: values.isActive,
  firstOrderOnly: values.firstOrderOnly,
  discountAmounts: isShopForm(values) ? {} : getDiscountAmounts(values),
  discountPct: parseInt(values.discountPct),
  discountServicePct: isShopForm(values) ? 0 : parseInt(values.discountServicePct),
  applicableOrders: featureFlags.APPLICABLE_BOXES && isBoxForm(values) ? parseInt(values.applicableOrders) : undefined,
  totalUses: parseInt(values.totalUses),
  frequency: isShopForm(values) ? undefined : values.frequency,
  services: isShopForm(values) ? ["SHOP"] : ["BOX"],
});

export const formValuesToPrimitiveBulk = (values: FormFields, featureFlags: FeatureFlags): PrimitiveBulk => {
  const { name, ...promocodeData } = formValuesToPrimitivePromocode(values, featureFlags);
  return {
    ...promocodeData,
    length: parseInt(values.promocodeLength),
    prefix: values.promocodePrefix,
    total: parseInt(values.numberOfPromocodes),
  };
};
