import React from "react";
import { useRadioGroup } from "../RadioGroup/useRadioGroup";
import "./Radio.css";

const Radio: React.FC<{
  label: string;
  value: string;
  disabled?: boolean;
}> = ({ label, value, disabled = false }) => {
  const { value: groupValue, onChange } = useRadioGroup();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <label className="text text--body form-control">
      <input
        className="radio"
        type="radio"
        value={value}
        checked={value === groupValue}
        onChange={handleInputChange}
        disabled={disabled}
      />
      {label}
    </label>
  );
};

export default Radio;
