import { AuthUserLoggedEvent, AuthUserLogoutEvent } from "@domain/auth/Events/UserAuth";
import { ApiAuthStorageRepository } from "@infrastructure/auth/ApiAuthStorageRepository";
import { useEventBus } from "./useEventBus";

import { Role, RoleAuthority } from "@core/domain/auth/Role";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Event } from "@core/domain/shared/EventBus";

type AuthContextType = {
  hasPromocodesWriteRole: () => boolean;
  loggedIn: boolean | null;
  roles: Role[];
  token: string;
};

const AuthContext = createContext<AuthContextType>({
  hasPromocodesWriteRole: () => false,
  loggedIn: null,
  roles: [],
  token: "",
});

export const AuthProvider: React.FC = ({ children }) => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [token, setToken] = useState<string>("");
  const eventBus = useEventBus();

  const updateAuthState = useCallback((token: string) => {
    const { userRoles } = decodeJwt(token);
    setRoles(userRoles);
    setToken(token);
    setLoggedIn(true);
  }, []);

  const clearAuthState = useCallback(() => {
    setRoles([]);
    setToken("");
    setLoggedIn(false);
  }, []);

  useEffect(() => {
    const token = ApiAuthStorageRepository.get();
    if (token) {
      updateAuthState(token);
    } else {
      clearAuthState();
    }
  }, [clearAuthState, updateAuthState]);

  useEffect(() => {
    const handleLogin = (event: Event<{ token: string }>) => {
      updateAuthState(event.payload.token);
    };

    const unsubscribeOnLogin = eventBus.subscribe(AuthUserLoggedEvent.name, handleLogin);
    const unsbscribeOnLogout = eventBus.subscribe(AuthUserLogoutEvent.name, clearAuthState);

    return () => {
      unsubscribeOnLogin();
      unsbscribeOnLogout();
    };
  }, [clearAuthState, eventBus, updateAuthState]);

  const hasRole = useCallback((role: Role): boolean => roles.includes(role), [roles]);
  const hasPromocodesWriteRole = useMemo(() => () => hasRole(Role.PROMOCODES_WRITE), [hasRole]);

  return (
    <AuthContext.Provider value={{ hasPromocodesWriteRole, loggedIn, roles, token }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => useContext(AuthContext);

const decodeJwt = (token: string) => {
  const [, payloadEncoded] = token.split(".");
  const payload = JSON.parse(atob(payloadEncoded));
  const email = payload.sub;
  const userRoles = payload.roles.map((role: RoleAuthority) => role.authority);
  return { email, userRoles };
};
