import { PromocodeRepository } from "@domain/promocode/PromocodeRepository";
import { valuePromocodeName } from "@domain/promocode/PromocodeName";
import { formatFileService } from "@domain/shared/FormatFileService";
import { DateGenerator } from "@domain/shared/DateRepository";

type ExportPromocodeCommand = { name: string };

export const ExportPromocode =
  (downloadPromocodeRepository: PromocodeRepository["download"], dateRepository: DateGenerator) =>
  async ({ name }: ExportPromocodeCommand): Promise<{ promocodes: string; name: string }> => {
    const promocodes = await downloadPromocodeRepository(valuePromocodeName(name));
    const fileName = formatFileService("PC", name, dateRepository());

    return {
      promocodes,
      name: fileName,
    };
  };
