import { FC } from "react";
import Header from "@organisms/Header/Header";
import SideMenu from "@organisms/SideMenu/SideMenu";

import "./MainLayout.css";

const MainLayout: FC = ({ children }) => {
  return (
    <div className="main-layout">
      <Header />
      <div className="main-layout__wrapper">
        <SideMenu />
        <div className="main-layout__views">{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
