import { MessageErrorEvent } from "@core/shared/MessageErrorEvent";
import { EventBus } from "@domain/shared/EventBus";

import { ApiPromocodeError } from "./ApiErrorEvent";

const UnlimitedPromotionSameNameCountry = "UNLIMITED_PROMOTION_SAME_NAME_COUNTRY";
const OverlappedDatesSameNameCountry = "OVERLAPPED_DATES_SAME_NAME_COUNTRY";
const StartDateBeforeActualDate = "START_DATE_BEFORE_ACTUAL_DATE";
const PromotionNotExists = "PROMOTION_NOT_EXISTS";
const PromotionFinished = "PROMOTION_FINISHED";
const NoEditAfterFinishDate = "NO_EDIT_AFTER_FINISH_DATE";
const AlreadyUsedPromotion = "ALREADY_USED_PROMOTION";
const MaxAllowedUses = "MAX_ALLOWED_USES";
const OtherBulkInProgress = "OTHER_BULK_IN_PROGRESS";

const UnexpectedErrorCode = "UNEXPECTED_ERROR_CODE";

type ErrorCodesGroup = Map<string, string[]>;

const AppBackErrors: ErrorCodesGroup = new Map([
  [OtherBulkInProgress, ["PSA-019"]],
  [UnlimitedPromotionSameNameCountry, ["PSA-001"]],
  [OverlappedDatesSameNameCountry, ["PSA-002"]],
  [StartDateBeforeActualDate, ["PSA-014"]],
  [PromotionNotExists, ["PSA-003"]],
  [PromotionFinished, ["PSA-012"]],
  [NoEditAfterFinishDate, ["PSA-015"]],
  [AlreadyUsedPromotion, ["PSA-013"]],
  [MaxAllowedUses, ["PSD-007"]],
]);

export const validateErrorCode =
  (eventBus: EventBus) =>
  (event: ApiPromocodeError): void => {
    const maybeCachedCode = Array.from(AppBackErrors.keys()).find((key) =>
      AppBackErrors.get(key)?.includes(event.payload.error_code),
    );

    if (maybeCachedCode !== undefined) {
      eventBus.publish(MessageErrorEvent.create({ message: maybeCachedCode }));
    } else {
      eventBus.publish(MessageErrorEvent.create({ message: UnexpectedErrorCode }));
    }
  };
