import Cookies from "js-cookie";

const CookieStorage = {
  set: (
    name: string,
    value: string | Record<string, unknown>,
    options?: Cookies.CookieAttributes | undefined,
  ): string | undefined => Cookies.set(name, value, options),
  get: (name: string): string | undefined => Cookies.get(name),
  remove: (name: string, options?: Cookies.CookieAttributes | undefined): void => Cookies.remove(name, options),
};

export { CookieStorage };
