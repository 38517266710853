export type Event<T> = {
  name: string;
  payload: T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventHandler = <E extends Event<any>>(event: E) => void;

export const eventOf = <T>(name: string) => ({
  name,
  create: (payload: T): Event<T> => ({
    name,
    payload,
  }),
});

export interface EventBus {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publish<E extends Event<any>>(event: E): void;
  subscribe(eventName: string, eventHandler: EventHandler): () => void;
}
