import React, { createContext, useContext } from "react";
import { Environment } from "../../core/domain/environment/Environment";

export const EnvironmentContext = createContext<Environment | null>(null);

export const EnvironmentProvider: React.FC<{ value: Environment }> = ({ value, ...props }) => (
  <EnvironmentContext.Provider {...props} value={value} />
);

export const useEnvironment = (): Environment => {
  const environment = useContext(EnvironmentContext);

  if (environment == null) {
    throw new Error("'useEnvironment' must be used within a 'EnvironmentProvider'");
  }

  return environment;
};
