import { UnauthorizedErrorEvent } from "@application/auth/UnauthorizedError/UnauthorizedErrorEvent";
import { ApiErrorEvent } from "@application/promocode/ValidateApiError/ApiErrorEvent";
import { isBackendError } from "@domain/promocode/Error";

import { ApiAuthStorageRepository } from "../auth/ApiAuthStorageRepository";
import { getEnvironment } from "../environment/HttpEnvironmentRepository";
import { eventBus } from "../shared/EventBus/EventBus";
import { HttpClient } from "../_persistence/HttpClient/HttpClient";

const unauthorizedError = (value: number) => value === 403 || value === 401;

// TODO: Move to factory
const PromocodeClient = HttpClient.create(
  () => getEnvironment().then((environment) => environment.endpoints.PROMOCODE_API_URL),
  {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  async (response) => {
    if (unauthorizedError(response.status)) {
      eventBus.publish(UnauthorizedErrorEvent.create(null));
      return Promise.reject();
    }

    const error = await response.json();
    if (isBackendError(error)) {
      eventBus.publish(ApiErrorEvent.create(error));
      return Promise.reject();
    }

    return Promise.reject(response);
  },
  (path, config) => {
    const token = ApiAuthStorageRepository.get();

    return new Request(
      path,
      token
        ? {
            ...config,
            headers: {
              ...config.headers,
              authorization: `Bearer ${token}`,
            },
          }
        : config,
    );
  },
);

export { PromocodeClient };
