type BooleanObject = {
  [key: string]: boolean;
};

export const arrayStringToBooleanObject = (array: string[]): BooleanObject => {
  return array.reduce((object: BooleanObject, item: string) => {
    object[item] = true;

    return object;
  }, {});
};
