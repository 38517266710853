import { Button, ButtonVariant, Field, Input, NotificationVariant, Text, TextVariant } from "@lookiero/react-ui-kit";
import React from "react";
import { Link } from "react-router-dom";

import Match, { Case } from "@atoms/Match/Match";
import Radio from "@atoms/Radio/Radio";
import RadioGroup from "@atoms/RadioGroup/RadioGroup";
import { useEnvironment } from "@hooks/useEnvironment";
import { Stack } from "@layouts/Stack/Stack";
import { useToastService } from "@layouts/ToastProvider/useToast";
import { ROUTES, useNavigator } from "@router";
import { PromocodeApplication } from "@workflow";

import PromocodeBulkStatus from "../../components/PromocodeBulkStatus/PromocodeBulkStatus";
import { useBulkStatus } from "../../components/PromocodeBulkStatus/useBulkStatus";
import PromocodeForm, { FormFields } from "../../components/PromocodeForm/PromocodeForm";
import TEXT_FORM from "../../components/PromocodeForm/PromocodeForm.intl.json";
import { FIELD, PROMOCODE_CREATION_MODES } from "../../components/PromocodeForm/PromocodeForm.schema";
import {
  formValuesToPrimitiveBulk,
  formValuesToPrimitivePromocode,
} from "../../components/PromocodeForm/utils/formValuesParser";

import { useAuth } from "@hooks/useAuth";
import "./PromocodeCreate.css";
import TEXT from "./PromocodeCreate.intl.json";

const PromocodeModes: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
  const { isProcessing } = useBulkStatus();

  return (
    <RadioGroup value={value} onChange={onChange}>
      <Radio label="Specific promocode" value={PROMOCODE_CREATION_MODES.single} />
      <Radio label="Bulk promocodes" value={PROMOCODE_CREATION_MODES.bulk} disabled={isProcessing} />
    </RadioGroup>
  );
};

const PromocodeCreate: React.FC = () => {
  const navigator = useNavigator();
  const toast = useToastService();

  const { featureFlags } = useEnvironment();
  const { hasPromocodesWriteRole } = useAuth();
  const authorized = hasPromocodesWriteRole();

  const handleSubmit = async (values: FormFields) => {
    if (values[FIELD.MODE] === PROMOCODE_CREATION_MODES.single) {
      handleCreatePromocode(values);
    } else {
      handleCreateBulk(values);
    }
  };

  const handleCreatePromocode = async (values: FormFields) => {
    const createPromocode = await PromocodeApplication.get("create");
    await createPromocode(formValuesToPrimitivePromocode(values, featureFlags));
    toast.push(<>{TEXT.TOAST_SUCCESS}</>, { variant: NotificationVariant.SUCCESS });
    navigator.goTo(ROUTES.PROMOCODES);
  };

  const handleCreateBulk = async (values: FormFields) => {
    const createBulk = await PromocodeApplication.get("createBulk");
    await createBulk(formValuesToPrimitiveBulk(values, featureFlags));
    toast.push(<>{TEXT.BULK_PROMOCODE_START}</>, {
      time: 10 * 1000,
      variant: NotificationVariant.BASIC,
    });
    navigator.goTo(ROUTES.PROMOCODES);
  };

  if (!authorized) return null;

  return (
    <div className="pc-create">
      <PromocodeBulkStatus />
      <PromocodeForm
        header={
          <>
            <Text tag="h1" variant={TextVariant.HEADING_1}>
              {TEXT.TITLE}
            </Text>
            <Stack flexDirection="row" spacing="xxs" alignItems="center">
              <Link to={ROUTES.PROMOCODES.path}>
                <Button variant={ButtonVariant.BASIC} className="promocode-create-buttons">
                  {TEXT.CANCEL_BTN}
                </Button>
              </Link>
              <Button variant={ButtonVariant.PRIMARY} className="promocode-create-buttons">
                {TEXT.CREATE_BTN}
              </Button>
            </Stack>
          </>
        }
        onSubmit={handleSubmit}
        promocodeIdentField={({ values, changeFieldValue, inputProps }) => (
          <>
            <PromocodeModes
              value={values[FIELD.MODE]}
              onChange={(value: string) => changeFieldValue({ name: FIELD.MODE, value })}
            />
            <Match>
              <Case when={values[FIELD.MODE] === PROMOCODE_CREATION_MODES.bulk}>
                <Field label="Number of codes to generate">
                  <Input {...inputProps(FIELD.NUMBER_OF_PROMOCODES, "Number of codes (1 - 50.000)", "number")} />
                </Field>
                <div className="promocode-form__group">
                  <Field label="Prefix name">
                    <Input
                      {...inputProps(FIELD.PROMOCODE_PREFIX, "e.g: HOLA")}
                      onChange={(value: string) =>
                        changeFieldValue({ name: FIELD.PROMOCODE_PREFIX, value: value.toUpperCase() })
                      }
                    />
                  </Field>
                  <Field label="Code length">
                    <Input {...inputProps(FIELD.PROMOCODE_LENGTH, "Code length", "number")} />
                  </Field>
                </div>
              </Case>
              <Case when={values[FIELD.MODE] === PROMOCODE_CREATION_MODES.single}>
                <Field label={TEXT_FORM.PROMOTION_LBL}>
                  <Input
                    {...inputProps(FIELD.NAME, TEXT_FORM.PROMOTION_PH)}
                    onChange={(value: string) => changeFieldValue({ name: FIELD.NAME, value: value.toUpperCase() })}
                  />
                </Field>
              </Case>
            </Match>
          </>
        )}
      />
    </div>
  );
};

export default PromocodeCreate;
