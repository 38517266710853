const isClientError = (value: number) => 400 <= value && value < 500;

const http = async <T>(
  path: string,
  config: RequestInit,
  middleware = (path: string, config: RequestInit) => new Request(path, config),
): Promise<T> => {
  const request = middleware(path, config);
  const response = await fetch(request);

  if (isClientError(response.status)) {
    return Promise.reject(response);
  }

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  if (request.headers?.get("Accept") === "application/json") {
    return JSON.parse((await response.text()) || "{}");
  }

  return (await response.text()) as unknown as T;
};

async function get<T>(
  path: string,
  query: { [key: string]: number | string | boolean } = {},
  config?: RequestInit,
  middleware?: (path: string, config: RequestInit) => Request,
): Promise<T> {
  const init = { method: "GET", ...config };
  const queryParams = Object.entries(query)
    .map((pair) => `${encodeURIComponent(pair[0])}=${encodeURIComponent(pair[1])}`)
    .join("&");

  return await http<T>(queryParams.length ? `${path}?${queryParams}` : path, init, middleware);
}

async function post<T, U>(
  path: string,
  body: T,
  config?: RequestInit,
  middleware?: (path: string, config: RequestInit) => Request,
): Promise<U> {
  const init = { method: "POST", body: JSON.stringify(body), ...config };
  return await http<U>(path, init, middleware);
}

async function put<T, U>(
  path: string,
  body: T,
  config?: RequestInit,
  middleware?: (path: string, config: RequestInit) => Request,
): Promise<U> {
  const init = { method: "PUT", body: JSON.stringify(body), ...config };
  return await http<U>(path, init, middleware);
}

async function remove<T>(
  path: string,
  config?: RequestInit,
  middleware?: (path: string, config: RequestInit) => Request,
): Promise<T> {
  const init = { method: "DELETE", ...config };
  return await http<T>(path, init, middleware);
}

type Client = {
  get: typeof get;
  put: typeof put;
  post: typeof post;
  delete: typeof remove;
};

const addHeaders = (headers: HeadersInit, config?: RequestInit): RequestInit => {
  if (!config) {
    return { headers };
  }

  if (!config.headers) {
    return {
      ...config,
      headers,
    };
  }

  return {
    ...config,
    headers: {
      ...headers,
      ...config.headers,
    },
  };
};

const HttpClient = {
  get,
  put,
  post,
  delete: remove,
  create(
    baseUrl: () => Promise<string>,
    headers: HeadersInit,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onCatch: (reason: Response) => any | PromiseLike<any> = (x) => Promise.reject(x),
    middleware?: (path: string, config: RequestInit) => Request,
  ): Client {
    return {
      async get<T>(
        path: string,
        query?: { [key: string]: number | string | boolean },
        config?: RequestInit,
      ): Promise<T> {
        return await get<T>((await baseUrl()) + path, query, addHeaders(headers, config), middleware).catch(onCatch);
      },

      async put<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
        return await put<T, U>((await baseUrl()) + path, body, addHeaders(headers, config), middleware).catch(onCatch);
      },

      async post<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
        return await post<T, U>((await baseUrl()) + path, body, addHeaders(headers, config), middleware).catch(onCatch);
      },

      async delete<T>(path: string, config?: RequestInit): Promise<T> {
        return await remove<T>((await baseUrl()) + path, addHeaders(headers, config), middleware).catch(onCatch);
      },
    };
  },
};

export { HttpClient };
