import { AuthStorageRepository } from "@domain/auth";
import { CookieStorage } from "../_persistence/CookieStorage/CookieStorage";

const TOKEN_KEY = "bearer_token";

const save = (token: string): string | undefined => CookieStorage.set(TOKEN_KEY, token);
const get = (): string | undefined => CookieStorage.get(TOKEN_KEY);
const remove = (): void => CookieStorage.remove(TOKEN_KEY);

const ApiAuthStorageRepository: AuthStorageRepository = { save, remove, get };
export { ApiAuthStorageRepository };
