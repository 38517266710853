import React from "react";
import { Card, Loader } from "@lookiero/react-ui-kit";

import { useBulkStatus } from "./useBulkStatus";
import "./PromocodeBulkStatus.css";

const PromocodeBulkStatus: React.FC = () => {
  const { isProcessing, message } = useBulkStatus();

  if (isProcessing && message?.payload) {
    return (
      <Card>
        <div className="promocode-bulk-status">
          <>
            <Loader />
            <span>
              <span className="promocode-bulk-status__bold">Creating </span>
              {`${message.payload.actual} of ${message.payload.total} promocodes.`}
            </span>
          </>
        </div>
      </Card>
    );
  }

  return null;
};

export default PromocodeBulkStatus;
