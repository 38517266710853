import { ROUTES } from "@router";
import { IconVariant } from "@lookiero/react-ui-kit";

type SideMenuItem = {
  icon: { variant: IconVariant; flip?: boolean; noFill?: boolean };
  path: string;
  text: string;
};

const SIDEMENU_ROUTE: Record<string, SideMenuItem> = {
  PROMOCODES: {
    icon: { variant: IconVariant.TAG, noFill: true },
    path: ROUTES.PROMOCODES.path,
    text: "Promocodes",
  },
};

const SIDEMENU_ROUTE_LIST = [SIDEMENU_ROUTE.PROMOCODES];

export { SIDEMENU_ROUTE_LIST };
