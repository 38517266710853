import { FrequencyObject } from "@core/domain/promocode/PromocodeFrequency";
import { AVAILABLE_SITES, Country, EURO_SITES } from "@core/domain/promocode/PromocodeSites";
import * as Yup from "yup";
import TEXT from "./PromocodeForm.intl.json";
import { getCheckboxGroupYupObject } from "./utils/schema";
import { buildFieldSitesObject, getSitesCheckboxList, getSitesDefaultValues, isPromoFromSite } from "./utils/sites";
import { FeatureFlags } from "@domain/environment/Environment";

const FIELD_SITE = buildFieldSitesObject(AVAILABLE_SITES);

const FIELD_FREQUENCY = {
  subscriber: "subscriber",
  onDemand: "onDemand",
};

export const PROMOCODE_CREATION_TYPES = {
  box: "box",
  shop: "shop",
};

export const PROMOCODE_CREATION_MODES = {
  single: "single",
  bulk: "bulk",
};

const FIELD = {
  TYPE: "type",
  MODE: "mode",
  NUMBER_OF_PROMOCODES: "numberOfPromocodes",
  PROMOCODE_PREFIX: "promocodePrefix",
  PROMOCODE_LENGTH: "promocodeLength",
  NAME: "name",
  DESC: "description",
  AMOUNT_EUR: "discountAmountEUR",
  AMOUNT_GBP: "discountAmountGBP",
  AMOUNT_SEK: "discountAmountSEK",
  AMOUNT_CHF: "discountAmountCHF",
  PCT: "discountPct",
  SERVICE_PCT: "discountServicePct",
  START_DATE: "startDate",
  END_DATE: "endDate",
  SITES: "sites",
  ...FIELD_SITE,
  TOTAL_USES: "totalUses",
  APPLICABLE_ORDERS: "applicableOrders",
  FIRST_ORDER: "firstOrderOnly",
  ADD_GIFT: "addGift",
  IS_ACTIVE: "isActive",
  FREQUENCY: "frequency",
  ...FIELD_FREQUENCY,
} as const;

const DEFAULT_VALUES: {
  [FIELD.TYPE]: string;
  [FIELD.MODE]: string;
  [FIELD.NUMBER_OF_PROMOCODES]: string;
  [FIELD.PROMOCODE_PREFIX]: string;
  [FIELD.PROMOCODE_LENGTH]: string;
  [FIELD.NAME]: string;
  [FIELD.DESC]: string;
  [FIELD.AMOUNT_EUR]?: string;
  [FIELD.AMOUNT_GBP]?: string;
  [FIELD.AMOUNT_SEK]?: string;
  [FIELD.AMOUNT_CHF]?: string;
  [FIELD.PCT]: string;
  [FIELD.SERVICE_PCT]: string;
  [FIELD.START_DATE]: Date | undefined;
  [FIELD.END_DATE]: Date | undefined;
  [FIELD.SITES]: Partial<Record<Country, boolean>>;
  [FIELD.APPLICABLE_ORDERS]: string;
  [FIELD.TOTAL_USES]: string;
  [FIELD.FIRST_ORDER]: boolean;
  [FIELD.ADD_GIFT]: boolean;
  [FIELD.IS_ACTIVE]: boolean;
  [FIELD.FREQUENCY]: FrequencyObject;
} = {
  [FIELD.TYPE]: PROMOCODE_CREATION_TYPES.box,
  [FIELD.MODE]: PROMOCODE_CREATION_MODES.single,
  [FIELD.NUMBER_OF_PROMOCODES]: "",
  [FIELD.PROMOCODE_PREFIX]: "",
  [FIELD.PROMOCODE_LENGTH]: "",
  [FIELD.NAME]: "",
  [FIELD.DESC]: "",
  [FIELD.AMOUNT_EUR]: "",
  [FIELD.AMOUNT_GBP]: "",
  [FIELD.AMOUNT_SEK]: "",
  [FIELD.AMOUNT_CHF]: "",
  [FIELD.PCT]: "",
  [FIELD.SERVICE_PCT]: "",
  [FIELD.START_DATE]: undefined,
  [FIELD.END_DATE]: undefined,
  [FIELD.SITES]: getSitesDefaultValues(FIELD_SITE),
  [FIELD.APPLICABLE_ORDERS]: "",
  [FIELD.TOTAL_USES]: "",
  [FIELD.FIRST_ORDER]: false,
  [FIELD.ADD_GIFT]: false,
  [FIELD.IS_ACTIVE]: true,
  [FIELD.FREQUENCY]: {
    subscriber: false,
    onDemand: false,
  },
};

const SITE_CHECKBOX = getSitesCheckboxList(FIELD_SITE);

const FREQUENCY_CHECKBOX = [
  { name: "subscriber", label: TEXT.SUBSCRIBER_LBL },
  { name: "onDemand", label: TEXT.ONDEMAND_LBL },
];

// const to save required length between validations to show it to user on screen.
// yup version 0.32.11 does not support a custom function that receive the form fields and schema for "when" function
// like version 1.0.0-beta.4. I have not updated to this version because is beta.
// So I must use a temp variable to save the min required value
let tempRequiredMinLength: number;

const isPositiveOrZero = (value = 0): boolean => value >= 0;

const allChecksAreFalse = (items: Record<string, boolean | undefined>): boolean => Object.values(items).some(Boolean);

function requiredMinLength(promoCodeQuantity: number, promocodePrefix: string) {
  const numberDigits = promoCodeQuantity.toString().length; // number of digits. Ex: 55 -> 2 length, 555 -> 3 length, 5555 -> 4 length
  const prefixLength = promocodePrefix.length;
  const offset = 1; // offset to make sure there is enough space to create all codes
  return prefixLength + numberDigits + offset;
}

function requiredMinLengthFrom9999To50k(promocodePrefix: string) {
  return promocodePrefix.length + 9;
}

const isBoxForm = (val: string) => val === PROMOCODE_CREATION_TYPES.box;

const isSingleForm = (val: string) => val === PROMOCODE_CREATION_MODES.single;

const isBulkForm = (val: string) => val === PROMOCODE_CREATION_MODES.bulk;

const PROMOTION_NAME_SCHEMA = Yup.string()
  .trim()
  .required(TEXT.INPUT_ERROR_REQUIRED)
  .matches(/^[A-Z0-9]*$/, TEXT.PROMOTION_NAME_WRONG_FORMAT);

const AMOUNT_SCHEMA = Yup.number().test({
  message: TEXT.INPUT_ERROR_NEGATIVE,
  test: isPositiveOrZero,
});

const NUMBER_SCHEMA = AMOUNT_SCHEMA.required(TEXT.INPUT_ERROR_REQUIRED);

const DISCOUNTS_SCHEMA_WHEN_OBJECT = {
  is: (...fields: number[]) => fields.every((value) => value === 0),
  then: (schema: Yup.NumberSchema) => schema.min(1, "Discount can't be zero"),
  otherwise: (schema: Yup.NumberSchema) => schema,
};

const END_DATE_SCHEMA = Yup.date().when(FIELD.START_DATE, (startDate: Date, schema) => {
  return startDate ? schema.min(startDate, TEXT.END_DATE_IS_EARLIER_THAN_START_DATE) : undefined;
});

const NUMBER_OF_PROMOCODES = Yup.number().when(FIELD.MODE, {
  is: isBulkForm,
  then: Yup.number()
    .required(TEXT.INPUT_ERROR_REQUIRED)
    .min(1, TEXT.INPUT_ERROR_MIN1)
    .max(50000, TEXT.INPUT_ERROR_MAX50K),
});

const PROMOCODE_PREFIX = Yup.string().when(FIELD.MODE, {
  is: isBulkForm,
  then: Yup.string().required(TEXT.INPUT_ERROR_REQUIRED).max(6, TEXT.INPUT_TEXT_ERROR_MAX6),
});

const PROMOCODE_LENGTH = Yup.number()
  // required validation
  .when(FIELD.MODE, {
    is: isBulkForm,
    then: Yup.number().required(TEXT.INPUT_ERROR_REQUIRED).min(1, TEXT.INPUT_ERROR_MIN1),
  })
  // length validation
  .when([FIELD.NUMBER_OF_PROMOCODES, FIELD.PROMOCODE_PREFIX, FIELD.PROMOCODE_LENGTH], {
    is: (numberOfPromocodes: number, promocodePrefix: string, promocodeLength: number) => {
      // if these fields are empty, this validation does not apply
      if (numberOfPromocodes === undefined || promocodePrefix === undefined || promocodeLength === undefined) {
        return false;
      }

      const isBetween9999And50k = numberOfPromocodes > 9999 && numberOfPromocodes < 50000;

      const minLength = isBetween9999And50k
        ? requiredMinLengthFrom9999To50k(promocodePrefix)
        : requiredMinLength(numberOfPromocodes, promocodePrefix);

      tempRequiredMinLength = minLength; // save required length between validations to show it to user on screen

      return promocodeLength < minLength;
    },
    then: (schema: Yup.NumberSchema) =>
      // Force a false test to set a custom message because it must run always the 'is' is true
      schema.test({ message: TEXT.BULK_PROMOCODES_CODE_REQUIRED_LENGTH + tempRequiredMinLength, test: () => false }),
  });

const MAX_100 = Yup.number().max(100, TEXT.INPUT_ERROR_MAX100);

const MIN_0 = Yup.number().min(0, TEXT.INPUT_ERROR_MIN0);

const getSchema = (featureFlags: FeatureFlags): Record<string, Yup.AnySchema> => ({
  // --- Form ---
  // Form mode selection
  [FIELD.MODE]: Yup.string(),
  // Bulk promocodes
  [FIELD.NUMBER_OF_PROMOCODES]: NUMBER_OF_PROMOCODES,
  [FIELD.PROMOCODE_PREFIX]: PROMOCODE_PREFIX,
  [FIELD.PROMOCODE_LENGTH]: PROMOCODE_LENGTH,
  // Specific promocode
  [FIELD.NAME]: Yup.string().when(FIELD.MODE, {
    is: isSingleForm,
    then: PROMOTION_NAME_SCHEMA,
  }),
  // --- Form and detail common fields ---
  [FIELD.DESC]: Yup.string().required(TEXT.INPUT_ERROR_REQUIRED),
  [FIELD.AMOUNT_EUR]: AMOUNT_SCHEMA.when([FIELD.TYPE, FIELD.SITES], {
    is: (type: string, sites: Partial<Record<Country, boolean>> = {}) =>
      isBoxForm(type) && isPromoFromSite(sites, EURO_SITES),
    then: NUMBER_SCHEMA.when([FIELD.PCT, FIELD.SERVICE_PCT], DISCOUNTS_SCHEMA_WHEN_OBJECT),
  }),
  [FIELD.AMOUNT_GBP]: AMOUNT_SCHEMA.when([FIELD.TYPE, FIELD.SITES], {
    is: (type: string, sites: Partial<Record<Country, boolean>> = {}) =>
      isBoxForm(type) && isPromoFromSite(sites, [Country.GB]),
    then: NUMBER_SCHEMA.when([FIELD.PCT, FIELD.SERVICE_PCT], DISCOUNTS_SCHEMA_WHEN_OBJECT),
  }),
  [FIELD.AMOUNT_SEK]: AMOUNT_SCHEMA.when([FIELD.TYPE, FIELD.SITES], {
    is: (type: string, sites: Partial<Record<Country, boolean>> = {}) =>
      isBoxForm(type) && isPromoFromSite(sites, [Country.SE]),
    then: NUMBER_SCHEMA.when([FIELD.PCT, FIELD.SERVICE_PCT], DISCOUNTS_SCHEMA_WHEN_OBJECT),
  }),
  [FIELD.AMOUNT_CHF]: AMOUNT_SCHEMA.when([FIELD.TYPE, FIELD.SITES], {
    is: (type: string, sites: Partial<Record<Country, boolean>> = {}) =>
      isBoxForm(type) && isPromoFromSite(sites, [Country.CH]),
    then: NUMBER_SCHEMA.when([FIELD.PCT, FIELD.SERVICE_PCT], DISCOUNTS_SCHEMA_WHEN_OBJECT),
  }),
  [FIELD.PCT]: NUMBER_SCHEMA.when(FIELD.TYPE, {
    is: isBoxForm,
    then: NUMBER_SCHEMA.when([FIELD.AMOUNT_EUR, FIELD.SERVICE_PCT], DISCOUNTS_SCHEMA_WHEN_OBJECT).concat(MAX_100),
  }),
  [FIELD.SERVICE_PCT]: Yup.number().when(FIELD.TYPE, {
    is: isBoxForm,
    then: NUMBER_SCHEMA.when([FIELD.AMOUNT_EUR, FIELD.PCT], DISCOUNTS_SCHEMA_WHEN_OBJECT).concat(MAX_100),
  }),
  [FIELD.START_DATE]: Yup.date(),
  [FIELD.END_DATE]: END_DATE_SCHEMA,
  [FIELD.FREQUENCY]: Yup.object().when(FIELD.TYPE, {
    is: isBoxForm,
    then: getCheckboxGroupYupObject(FIELD_FREQUENCY).test({
      message: TEXT.INPUT_ERROR_REQUIRED,
      test: allChecksAreFalse,
    }),
  }),
  [FIELD.SITES]: getCheckboxGroupYupObject(FIELD_SITE).test({
    message: TEXT.INPUT_ERROR_REQUIRED,
    test: allChecksAreFalse,
  }),
  [FIELD.TOTAL_USES]: MIN_0,
  ...(featureFlags.APPLICABLE_BOXES && {
    [FIELD.APPLICABLE_ORDERS]: MIN_0,
  }),
});

const PROMOCODE_SCHEMA = (featureFlags: FeatureFlags) =>
  Yup.object().shape(getSchema(featureFlags), [
    // exclude cyclic dependencies
    [FIELD.PROMOCODE_LENGTH, FIELD.PROMOCODE_LENGTH],
  ]);

export { DEFAULT_VALUES, FIELD, PROMOCODE_SCHEMA, SITE_CHECKBOX, FREQUENCY_CHECKBOX };
