import { AuthStorageRepository } from "@domain/auth";
import { AuthUserLogoutEvent } from "@domain/auth/Events/UserAuth";
import { EventBus } from "@domain/shared/EventBus";

type LogoutService = () => Promise<void | null>;

const Logout =
  (AuthStorageRepository: AuthStorageRepository, eventBus: EventBus): LogoutService =>
  async () => {
    try {
      AuthStorageRepository.remove();
      eventBus.publish(AuthUserLogoutEvent.create(null));
      return Promise.resolve();
    } catch (error) {
      // @TODO: manage error
    }
  };

export { Logout };
