import Home from "@pages/home/Home";
import Login from "@pages/login/Login";
import NotFound from "@pages/notFound/NotFound";
import Promocodes from "@promocodes/pages/Promocodes/Promocodes";
import PromocodeCreate from "@promocodes/pages/PromocodeCreate/PromocodeCreate";
import PromocodeDetail from "@promocodes/pages/PromocodeDetail/PromocodeDetail";
import PromocodeEdit from "@promocodes/pages/PromocodeEdit/PromocodeEdit";

export type RouteType = {
  path: string;
  component: React.FC;
  secured: boolean;
  exact: boolean;
};

enum RouteKey {
  PROMOCODES = "PROMOCODES",
  HOME = "HOME",
  LOGIN = "LOGIN",
  NOTFOUND = "NOTFOUND",
  PROMOCODES_ADD = "PROMOCODES_ADD",
  PROMOCODES_DETAIL = "PROMOCODES_DETAIL",
  PROMOCODES_EDIT = "PROMOCODES_EDIT",
}

export const ROUTES: Record<RouteKey, RouteType> = {
  PROMOCODES: { path: "/promocodes", component: Promocodes, exact: true, secured: true },
  PROMOCODES_ADD: { path: "/promocodes/add", component: PromocodeCreate, exact: true, secured: true },
  PROMOCODES_DETAIL: {
    path: "/promocodes/detail/:promocodeKey",
    component: PromocodeDetail,
    exact: true,
    secured: true,
  },
  PROMOCODES_EDIT: {
    path: "/promocodes/edit/:promocodeKey",
    component: PromocodeEdit,
    exact: true,
    secured: true,
  },
  HOME: { path: "/", component: Home, exact: true, secured: true },
  LOGIN: { path: "/login", exact: true, component: Login, secured: false },
  NOTFOUND: { path: "/404", exact: true, component: NotFound, secured: true },
};
