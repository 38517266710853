import React, { useContext, createContext, useEffect, useState } from "react";
import { NotificationVariant } from "@lookiero/react-ui-kit";

import { Connection } from "@domain/shared/EventSource";
import { useToastService } from "@layouts/ToastProvider/useToast";
import { PromocodeApplication } from "@workflow";
import { BulkStatusMessage } from "@domain/promocode/BulkStatus";
import { useAuth } from "@hooks/useAuth";
import { areObjectsDeepEqual } from "@utils/areObjectsDeepEqual";

import TEXT from "./PromocodeBulkStatus.intl.json";

type BulkStatusPayload = {
  message?: BulkStatusMessage;
  isProcessing: boolean;
};

const BulkStatusContext = createContext<BulkStatusPayload | undefined>(undefined);

export const BulkStatusProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<BulkStatusMessage>();
  const { push } = useToastService();
  const loggedIn = useAuth();

  const onMessage = (data: BulkStatusMessage) => {
    setMessage((current) => (areObjectsDeepEqual(data, current) ? current : data));
  };

  useEffect(() => {
    let connection: Connection;

    const connect = async () => {
      try {
        const bulkStatus = await PromocodeApplication.get("bulkStatus");
        connection = await bulkStatus({ onMessage });
      } catch {
        push(<>{TEXT.ERROR_BULK_STATUS}</>, {
          variant: NotificationVariant.DESTRUCTIVE,
        });
      }
    };

    if (loggedIn) connect();

    return () => {
      if (connection) connection.close();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <BulkStatusContext.Provider value={{ message, isProcessing: Boolean(message?.payload) }}>
      {children}
    </BulkStatusContext.Provider>
  );
};

export const useBulkStatus = (): BulkStatusPayload => {
  const context = useContext(BulkStatusContext);

  if (context === undefined) {
    throw new Error("useBulkStatus must be used within a BulkStatusProvider");
  }

  return context;
};
