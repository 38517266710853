import { PromocodeRepository } from "@domain/promocode";
import { BulkStatusMessage } from "@domain/promocode/BulkStatus";

import { PromocodeClient } from "./PromocodeClient";
import { ApiPromocode, ApiPromocodeList, apiToPromocode, promocodeToApi } from "./dto/Promocode";
import { bulkStatusParser, bulkToApi } from "./dto/Bulk";

const createPromocode: PromocodeRepository["createPromocode"] = (promocode) => {
  return PromocodeClient.http.post("/api/v1/promotions/promotions", promocodeToApi(promocode));
};

const editPromocode: PromocodeRepository["editPromocode"] = (key: number, promocode) => {
  return PromocodeClient.http.put(`/api/v1/promotions/promotions/${key}`, promocodeToApi(promocode));
};

const find: PromocodeRepository["find"] = ({ page = 0, offset: size = 10, promocode: name = "" }) =>
  PromocodeClient.http.get<ApiPromocodeList>("/api/v1/promotions/promotions", { page, size, name }).then((res) => ({
    totalElements: res.total_elements,
    promocodes: res.content.map(apiToPromocode),
  }));

const get: PromocodeRepository["get"] = (key: number) =>
  PromocodeClient.http.get<ApiPromocode>(`/api/v1/promotions/promotions/${key}`).then(apiToPromocode);

const download: PromocodeRepository["download"] = (name) =>
  PromocodeClient.http
    .get<string>(
      "/api/v1/promotions/promotions/download",
      { name },
      {
        headers: {
          Accept: "text/csv",
        },
      },
    )
    .then((csv) => URL.createObjectURL(new Blob([csv], { type: "text/csv" })));

const createBulk: PromocodeRepository["createBulk"] = (bulk) =>
  PromocodeClient.http.post("/api/v1/promotions/promotions/bulk", bulkToApi(bulk));

const bulkStatus: PromocodeRepository["bulkStatus"] = (callbacks) =>
  PromocodeClient.eventSource.connect<string, BulkStatusMessage>(
    "/api/v1/promotions/promotions/bulk/progress",
    callbacks,
    bulkStatusParser,
  );

const ApiPromocodeRepository: PromocodeRepository = {
  createPromocode,
  editPromocode,
  get,
  find,
  download,
  createBulk,
  bulkStatus,
};

export { ApiPromocodeRepository };
