import { valueDate, ValueDate } from "../shared/Date";
import { valuePercentage, ValuePercentage } from "../shared/Percentage";
import { PromocodeName, valuePromocodeName } from "./PromocodeName";
import { PromocodeSites, valuePromocodeSites } from "./PromocodeSites";
import { FrequencyList, FrequencyObject, toFrequencyList } from "./PromocodeFrequency";

// TODO: move Device to value object.
export type Device = "WEB" | "APP";
export type Service = "BOX" | "SHOP";

export const isShopPromoCode = (promocode: Promocode): boolean => {
  return promocode.services && promocode.services.includes("SHOP");
};

export const AvailableCurrencies = {
  EUR: "EUR",
  GBP: "GBP",
  SEK: "SEK",
  CHF: "CHF",
} as const;
export type Currency = keyof typeof AvailableCurrencies;
export type DiscountAmounts = Partial<Record<keyof typeof AvailableCurrencies, number | undefined>>;

export type Promocode = {
  key?: number;
  name: PromocodeName;
  description: string;
  discountAmounts: DiscountAmounts;
  discountPct: ValuePercentage;
  discountServicePct: ValuePercentage;
  startDate?: ValueDate;
  endDate?: ValueDate;
  sites: PromocodeSites;
  addGift: boolean;
  isActive: boolean;
  applicableOrders?: number;
  totalUses?: number;
  currentUses?: number;
  firstOrderOnly: boolean;
  frequency?: FrequencyList;
  devices?: Device[]; // TODO
  services: Service[];
};

export type PrimitivePromocode = {
  key?: number;
  name: string;
  description: string;
  discountAmounts: DiscountAmounts;
  discountPct: number;
  discountServicePct: number;
  startDate?: Date;
  endDate?: Date;
  sites: { [x: string]: boolean };
  addGift: boolean;
  isActive: boolean;
  applicableOrders?: number;
  totalUses?: number;
  currentUses?: number;
  firstOrderOnly: boolean;
  frequency?: FrequencyObject;
  devices?: Device[]; // TODO
  services: Service[];
};

export const fromPrimitive = ({
  key,
  name,
  description,
  discountAmounts,
  discountPct,
  discountServicePct = 0,
  startDate,
  endDate,
  sites,
  addGift,
  isActive,
  applicableOrders,
  totalUses,
  currentUses = 0,
  firstOrderOnly,
  frequency,
  services,
}: PrimitivePromocode): Promocode => {
  return {
    key,
    name: valuePromocodeName(name),
    description,
    discountAmounts,
    discountPct: valuePercentage(discountPct),
    discountServicePct: valuePercentage(discountServicePct),
    startDate: startDate ? valueDate(startDate) : undefined,
    endDate: endDate ? valueDate(endDate) : undefined,
    sites: valuePromocodeSites(sites),
    addGift,
    isActive,
    currentUses,
    applicableOrders,
    totalUses,
    firstOrderOnly,
    frequency: frequency ? toFrequencyList(frequency) : undefined,
    services: services,
  };
};
