import React, { useContext, createContext } from "react";
import { EventBus } from "@domain/shared/EventBus";

const EventBusContext = createContext<EventBus | null>(null);

export const EventBusProvider: React.FC<{ value: EventBus }> = ({ value, ...props }) => (
  <EventBusContext.Provider value={value} {...props} />
);

export const useEventBus = (): EventBus => {
  const eventBus = useContext(EventBusContext);

  if (eventBus === null) {
    throw new Error("'useEventBus must be used within a EventBusProvider'");
  }

  return eventBus;
};
