import { FC } from "react";
import { Text, TextVariant } from "@lookiero/react-ui-kit";
import { ReactComponent as Logo } from "@components/_assets/svg/logo.svg";

import "./Header.css";

const Header: FC = () => {
  return (
    <div className="header">
      <div className="header__container">
        <div className="header__logo">
          <Logo className="header__svg" />
        </div>
        <div className="header__name">
          <Text variant={TextVariant.HEADING_3}>Growth Control Panel</Text>
        </div>
      </div>
    </div>
  );
};

export default Header;
