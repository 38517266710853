import { memorize } from "../../../utils/memorize";
import { Environment } from "../../domain/environment/Environment";
import { GetEnvironmentRepository } from "../../domain/environment/EnvironmentRepository";
import { HttpClient } from "../_persistence/HttpClient/HttpClient";

export const DEFAULT_ENVIRONMENT: Environment = {
  system: {
    environment: "dev",
  },
  endpoints: {
    PROMOCODE_API_URL: "https://grw-panel-api.dev.envs.lookiero.tech",
  },
  featureFlags: {
    APPLICABLE_BOXES: true,
    SHOP_PROMOS: true,
  },
};

export const getEnvironment: GetEnvironmentRepository = memorize(() =>
  HttpClient.get<Environment>(
    "/config.json",
    {},
    {
      headers: {
        Accept: "application/json",
      },
    },
  ).catch(() => DEFAULT_ENVIRONMENT),
);
