import { FC } from "react";
import { IconVariant, TextVariant } from "@lookiero/react-ui-kit";

import { AuthApplication } from "@workflow";
import { useHistory, useLocation } from "@router";

import SideMenuItem from "./SideMenuItem";
import { SIDEMENU_ROUTE_LIST } from "./SideMenu.config";

import "./SideMenu.css";

const SideMenu: FC = () => {
  const { pathname: currentPath } = useLocation();
  const { push } = useHistory();

  const goTo = (path: string) => {
    if (currentPath !== path) {
      push(path);
    }
  };

  const handleLogout = async () => {
    const logout = await AuthApplication.get("logout");
    logout();
  };

  return (
    <div className="side-menu">
      <div>
        {SIDEMENU_ROUTE_LIST.map(({ icon, path, text }) => (
          <SideMenuItem
            key={path}
            active={currentPath.startsWith(path)}
            icon={icon}
            text={text}
            onClick={() => goTo(path)}
          />
        ))}
      </div>
      <SideMenuItem
        icon={{ variant: IconVariant.LOGOUT }}
        text="Log out"
        textVariant={TextVariant.BODY}
        onClick={handleLogout}
      />
    </div>
  );
};

export default SideMenu;
