import React, { useEffect, useState } from "react";
import { Notification, Stack, StackElement } from "@lookiero/react-ui-kit";
import { Toast as ToastProps, ToastContext, useToastList, useToastService } from "./useToast";

const Toast: React.FC<ToastProps & { id: string }> = ({ id, time = 10 * 1000, ...props }) => {
  const notificationService = useToastService();

  useEffect(() => {
    const intervalId = setTimeout(() => {
      if (props.onClose) {
        props.onClose();
      }
      notificationService.remove(id);
    }, time);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, id]);

  return (
    <Notification
      {...props}
      onCancel={() => {
        if (props.onClose) {
          props.onClose();
        }
        notificationService.remove(id);
      }}
    />
  );
};

const ToastStack: React.FC = () => {
  const toast = useToastList();

  return (
    <Stack className="toast__stack">
      {toast.map((props) => <Toast {...props} id={props.key} />) as StackElement[]}
    </Stack>
  );
};

const ToastProvider: React.FC = ({ children }) => {
  const notificationState = useState<ToastProps[]>([]);

  return (
    <ToastContext.Provider value={notificationState}>
      <ToastStack />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
