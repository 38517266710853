import { NotificationVariant } from "@lookiero/react-ui-kit";
import { createContext, useContext } from "react";
import "./ToastProvider.css";

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

export type Toast = {
  key: string;
  className?: string;
  variant?:
    | NotificationVariant.BASIC
    | NotificationVariant.ALERT
    | NotificationVariant.DESTRUCTIVE
    | NotificationVariant.SUCCESS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: React.ReactElement<any, any> | null;
  onClose?: () => void;
  time?: number;
};

type State<Value> = [
  Value,
  {
    (value: Value): void;
    (fn: (value: Value) => Value): void;
  },
];

export const ToastContext = createContext<State<Toast[]>>([[], (x) => x]);

export const useToastService = () => {
  const [, setToast] = useContext(ToastContext);

  return {
    push(content: Toast["children"], toast?: Omit<Toast, "key" | "children">) {
      const key = crypto.randomUUID();

      setToast((toasts) => [
        ...toasts,
        {
          key,
          children: content,
          ...toast,
        },
      ]);

      return key;
    },
    remove(key: Toast["key"]) {
      setToast((toasts) => toasts.filter((toast) => toast.key !== key));
    },
  };
};

export const useToastList = () => {
  const [alert] = useContext(ToastContext);

  return alert;
};
