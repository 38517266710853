import TEXT from "../PromocodeForm.intl.json";
import { Country } from "@domain/promocode/PromocodeSites";

type Intl = { [key: string]: string };
type Sites = Partial<Record<Country, string>>;
type SitesObjectBoolean = Partial<Record<Country, boolean>>;
type SitesCheckboxList = { name: string; label: string };

export const buildFieldSitesObject = (sites: Country[]): Sites => {
  return sites.reduce((sitesObject: Sites, site) => {
    sitesObject[site] = site;
    return sitesObject;
  }, {});
};

export const getSitesDefaultValues = (sites: Sites): SitesObjectBoolean => {
  return Object.keys(sites).reduce((defaultValues: SitesObjectBoolean, site) => {
    defaultValues[site as Country] = false;
    return defaultValues;
  }, {});
};

export const getSitesCheckboxList = (sites: Sites): SitesCheckboxList[] => {
  return Object.keys(sites).map((siteKey: string) => ({
    name: sites[siteKey as Country] as string,
    label: (TEXT as Intl)[`${siteKey}_LBL`],
  }));
};

export const isPromoFromSite = (promoSites: SitesObjectBoolean, sitesToCheck: Country[]): boolean => {
  return Object.entries(promoSites).some(([site, selected]) => selected && sitesToCheck.includes(site as Country));
};
