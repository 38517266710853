import * as Yup from "yup";
import TEXT from "./Login.intl.json";

const FIELD = {
  EMAIL: "email",
  PASSWORD: "password",
} as const;

const EMAIL_REGEX = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
const LOGIN_SCHEMA = Yup.object({
  [FIELD.EMAIL]: Yup.string()
    .trim()
    .matches(new RegExp(EMAIL_REGEX), TEXT.INPUT_ERROR_EMAIL)
    .required(TEXT.INPUT_ERROR_REQUIRED),
  [FIELD.PASSWORD]: Yup.string().trim().required(TEXT.INPUT_ERROR_REQUIRED),
});

export { FIELD, LOGIN_SCHEMA };
