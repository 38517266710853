import {
  Button,
  ButtonVariant,
  Icon,
  IconVariant,
  Input,
  Loader,
  NotificationVariant,
  Text,
  TextVariant,
} from "@lookiero/react-ui-kit";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import Match, { Case } from "@atoms/Match/Match";
import { useDebounceEffect } from "@hooks/useDebounceEffect";
import { Stack } from "@layouts/Stack/Stack";
import { useToastService } from "@layouts/ToastProvider/useToast";
import { ROUTES, useNavigator } from "@router";
import { downloadFile } from "@utils/downloadFile";
import { PromocodeApplication } from "@workflow";

import { Currency, Promocode } from "@domain/promocode";
import { useAuth } from "@hooks/useAuth";
import PromocodeBulkStatus from "../../components/PromocodeBulkStatus/PromocodeBulkStatus";
import PromocodeEmpty from "../../components/PromocodeEmpty/PromocodeEmpty";
import PromocodeEmptySearch from "../../components/PromocodeEmptySearch/PromocodeEmptySearch";
import PromocodeList from "../../components/PromocodeList/PromocodeList";
import "./Promocodes.css";
import TEXT from "./Promocodes.intl.json";

type Omitted = "discountAmounts" | "key";
export type PromoCodeTableElement = Omit<Promocode, Omitted> & {
  discountAmountEUR?: number;
  discountAmountSEK?: number;
  discountAmountGBP?: number;
  discountAmountCHF?: number;
  key: string;
};

const toTablePromoCodes = (promoCodes: Promocode[]): PromoCodeTableElement[] => {
  return promoCodes.map(({ discountAmounts, key, ...rest }) => {
    const promo: PromoCodeTableElement = { ...rest, key: String(key) };
    Object.entries(discountAmounts).forEach(([currency, amount]) => {
      promo[`discountAmount${currency as Currency}`] = amount;
    });
    return promo;
  });
};

const Promocodes: React.FC = () => {
  const toast = useToastService();
  const navigator = useNavigator();
  const [promoCodes, setPromoCodes] = useState<PromoCodeTableElement[]>([]);
  const [page, setPage] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const { hasPromocodesWriteRole } = useAuth();
  const [promoCodeToSearch, setPromoCodeToSearch] = useState("");
  const creationAuthorized = hasPromocodesWriteRole();

  const onHandlePageChange = (newPage: number) => {
    setPage(newPage - 1);
  };

  const handleSearchChange = (filterValue: string) => {
    setPage(0);
    setPromoCodeToSearch(filterValue);
  };

  const handleExportPromoCodes = async () => {
    setIsDownloading(true);
    try {
      const exportPromoCodes = await PromocodeApplication.get("export");
      const { name, promocodes } = await exportPromoCodes({ name: promoCodeToSearch });

      downloadFile(promocodes, `${name}.csv`);
    } finally {
      setIsDownloading(false);
    }
  };

  useDebounceEffect(
    async () => {
      try {
        const findPromoCodes = await PromocodeApplication.get("find");
        const { promocodes, totalElements } = await findPromoCodes({ page, promocode: promoCodeToSearch });

        setTotalItems(totalElements);
        setPromoCodes(toTablePromoCodes(promocodes));
      } catch (err) {
        if (err) toast.push(<>{TEXT.ERROR_PULL_PROMOCODES}</>, { variant: NotificationVariant.DESTRUCTIVE });
      }
    },
    300,
    [page, promoCodeToSearch],
  );

  const isExportPromocodesDisabled = promoCodeToSearch.length <= 0 || isDownloading;

  return (
    <section className="promocodes">
      <PromocodeBulkStatus />
      <div className="promocodes__header">
        <Text variant={TextVariant.HEADING_1}>{TEXT.LIST_TITLE_PROMOCODES}</Text>
        <Stack flexDirection="row" spacing="xxs" alignItems="center">
          <Button
            className="promocodes-buttons export-promocodes"
            onClick={handleExportPromoCodes}
            variant={ButtonVariant.BASIC}
            disabled={isExportPromocodesDisabled}
          >
            {isDownloading ? (
              <Loader />
            ) : (
              <>
                <Icon variant={IconVariant.EXPORT} className="export-promocodes__icon" />
                {TEXT.EXPORT_PROMOCODES}
              </>
            )}
          </Button>
          {creationAuthorized && (
            <Link to={ROUTES.PROMOCODES_ADD.path}>
              <Button variant={ButtonVariant.PRIMARY} className="promocodes-buttons">
                {TEXT.CREATE_PROMOCODES}
              </Button>
            </Link>
          )}
        </Stack>
      </div>
      <div className={`promocodes__list ${promoCodes.length <= 0 ? "promocodes__list--empty" : ""}`}>
        <div className="promocodes__search">
          <Input icon={IconVariant.SEARCH} value={promoCodeToSearch} onChange={handleSearchChange} />
        </div>
        <Match fallback={<PromocodeEmpty />}>
          <Case when={promoCodes.length > 0}>
            <PromocodeList
              promocodes={promoCodes}
              itemsInPage={10}
              page={page + 1}
              totalItems={totalItems}
              onChangePage={onHandlePageChange}
              onClickPromocode={(promocodeKey) => navigator.goTo(ROUTES.PROMOCODES_DETAIL, { promocodeKey })}
            />
          </Case>
          <Case when={promoCodes.length <= 0 && promoCodeToSearch !== ""}>
            <PromocodeEmptySearch promocode={promoCodeToSearch} />
          </Case>
        </Match>
      </div>
    </section>
  );
};

export default Promocodes;
