import { PromocodeRepository } from "@domain/promocode/PromocodeRepository";
import { BulkStatusMessage } from "@domain/promocode/BulkStatus";
import { Connection } from "@domain/shared/EventSource";

type Callbacks = { onMessage: (data: BulkStatusMessage) => void };
type BulkStatusPromocode = (repository: PromocodeRepository) => (callbacks: Callbacks) => Promise<Connection>;

const BulkStatusPromocode: BulkStatusPromocode = (repository) => async (callbacks) => {
  try {
    return repository.bulkStatus(callbacks);
  } catch (error) {
    // @TODO: ERROR
    return Promise.reject();
  }
};

export { BulkStatusPromocode };
