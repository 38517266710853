import React from "react";
import { Icon, IconVariant, Text, TextVariant } from "@lookiero/react-ui-kit";

import "./PromocodeEmptySearch.css";

const PromocodeEmptySearch: React.FC<{ promocode: string }> = ({ promocode }) => {
  return (
    <div className="empty-promocodes-search__content">
      <section className="empty-promocodes-search__section">
        <div className="empty-promocodes-search__icon">
          <Icon variant={IconVariant.SEARCH} className="icon__detail" />
        </div>
        <div className="empty-promocodes-search__title">
          <Text variant={TextVariant.HEADING_1}>Sorry we couldn’t find any matches for {promocode}</Text>
        </div>
        <div className="empty-promocodes-search__body">
          <Text variant={TextVariant.BODY}>Please try searching with another term</Text>
        </div>
      </section>
    </div>
  );
};

export default PromocodeEmptySearch;
