import React from "react";
import { Redirect, useHistory } from "react-router-dom";

import LoginForm from "@organisms/LoginForm/LoginForm";
import { ROUTES } from "@router";
import { AuthApplication } from "@workflow";

import "./Login.css";
import { useAuth } from "@hooks/useAuth";

// @TODO: change notification
// @TODO: review styles
const Login: React.FC = () => {
  const history = useHistory();
  const { loggedIn } = useAuth();

  const handleSubmitLogin = async ({ email, password }: { email: string; password: string }): Promise<void> => {
    try {
      const login = await AuthApplication.get("login");
      await login({ email, password });
      history.push(ROUTES.HOME.path);
    } catch {
      // @TODO: manage error
    }
  };

  if (loggedIn) {
    return <Redirect to={{ pathname: ROUTES.HOME.path }} />;
  }

  return <LoginForm onSubmit={handleSubmitLogin} />;
};

export default Login;
