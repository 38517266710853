import { ApiAuthRepository } from "@infrastructure/auth/ApiAuthRepository";
import { ApiAuthStorageRepository } from "@infrastructure/auth/ApiAuthStorageRepository";
import { eventBus } from "@infrastructure/shared/EventBus/EventBus";
import { Login } from "@application/auth/Login";
import { Logout } from "@application/auth/Logout";

import Container, { toFakeImport } from "../shared/Container";

const AuthApplication = Container.of({
  login: toFakeImport(Login(ApiAuthRepository, ApiAuthStorageRepository, eventBus)),
  logout: toFakeImport(Logout(ApiAuthStorageRepository, eventBus)),
});

export { AuthApplication };
