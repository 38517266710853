import { AuthRepository, AuthStorageRepository } from "@domain/auth";
import { AuthUserLoggedEvent } from "@domain/auth/Events/UserAuth";
import { EventBus } from "@domain/shared/EventBus";

type LoginService = ({ email, password }: { email: string; password: string }) => Promise<void | null>;

const Login =
  (authRepository: AuthRepository, authStorageRepository: AuthStorageRepository, eventBus: EventBus): LoginService =>
  async ({ email, password }) => {
    if (!email || !password) {
      // @TODO: manage error
      return Promise.reject(null);
    }

    try {
      const { token } = await authRepository.login({ email, password });
      authStorageRepository.save(token);
      eventBus.publish(AuthUserLoggedEvent.create({ token }));
      return Promise.resolve();
    } catch (error) {
      // @TODO: manage error
    }
  };

export { Login };
