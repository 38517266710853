import React from "react";
import { Checkbox, CheckboxState, DatePicker, Field, Input, Text, TextVariant } from "@lookiero/react-ui-kit";
import { useFormik } from "formik";

import Divider from "@atoms/Divider/Divider";
import CheckboxGroup from "@organisms/CheckboxGroup/CheckboxGroup";
import { useEnvironment } from "@hooks/useEnvironment";
import Match, { Case } from "@atoms/Match/Match";
import RadioGroup from "@atoms/RadioGroup/RadioGroup";
import Radio from "@atoms/Radio/Radio";

import {
  DEFAULT_VALUES,
  FIELD,
  PROMOCODE_SCHEMA,
  SITE_CHECKBOX,
  FREQUENCY_CHECKBOX,
  PROMOCODE_CREATION_TYPES,
} from "./PromocodeForm.schema";
import TEXT from "./PromocodeForm.intl.json";
import { isBoxForm, isShopForm } from "./utils/formValuesParser";
import "./PromocodeForm.css";
import { Country, EURO_SITES } from "@domain/promocode/PromocodeSites";

export type FormFields = typeof DEFAULT_VALUES;

type InputType = "number" | "search" | "text" | "password" | undefined;

type ChangeFieldValue = {
  name: string;
  value: string;
};

type ChangeFrequencyValue = {
  name: string;
  value: boolean;
  frequency: FormFields["frequency"];
};

type ChangeSitesValue = {
  name: string;
  value: boolean;
  sites: FormFields["sites"];
};

type ChangeCheckboxValue = {
  name: string;
  value: boolean;
};

type ChangeDateValue = {
  name: string;
  value: Date;
};

type PromocodeFormProps = {
  header?: React.ReactNode;
  promocodeIdentField: React.FC<{
    values: FormFields;
    changeFieldValue: (value: ChangeFieldValue) => void;
    inputProps: (
      name: keyof FormFields,
      placeholder: string,
      type?: InputType,
    ) => {
      name: string;
      onChange: (value: string) => void;
      type: "number" | "search" | "text" | "password";
      placeholder: string;
      value: string;
      error: string | undefined;
    };
  }>;
  defaultValues?: FormFields;
  onSubmit: (values: FormFields) => void;
  disabled?: boolean;
  disabledFields?: { [key: string]: boolean };
};

const PromocodeForm: React.FC<PromocodeFormProps> = ({
  header,
  defaultValues = DEFAULT_VALUES,
  onSubmit,
  disabled,
  disabledFields,
  promocodeIdentField: PromocodeIdentField,
}) => {
  const { featureFlags } = useEnvironment();

  const { values, errors, handleSubmit, setFieldValue, setFieldTouched, touched, setValues } = useFormik({
    initialValues: defaultValues,
    onSubmit,
    validationSchema: () => PROMOCODE_SCHEMA(featureFlags),
  });

  const changeFieldValue = ({ name, value }: ChangeFieldValue): void => {
    setFieldTouched(name);
    setFieldValue(name, value);
  };

  const changeCheckboxValue = ({ name, value }: ChangeCheckboxValue): void => {
    setFieldValue(name, value);
  };

  const inputProps = (name: keyof FormFields, placeholder: string, type: InputType = "text") => ({
    name,
    onChange: (value: string) => changeFieldValue({ name, value }),
    type,
    placeholder,
    value: values[name] as string,
    error: touched[name] && errors[name] ? (errors[name] as string) : undefined,
  });

  const changeFrequencyValue = ({ name, value, frequency: prevFrequency }: ChangeFrequencyValue): void => {
    const frequency = { ...prevFrequency, [name]: value };
    setFieldValue(FIELD.FREQUENCY, frequency, true);
  };

  const changeAllFrequency = ({ group, value }: { group: Record<string, boolean>; value: boolean }) => {
    const updatedFrequency: Record<string, boolean> = {};

    Object.keys(group).forEach((site) => (updatedFrequency[site] = value));
    setValues({ ...values, frequency: updatedFrequency as typeof values.frequency });
  };

  const changeSitesValue = ({ name, value, sites: prevSites }: ChangeSitesValue): void => {
    const sites = { ...prevSites, [name]: value };
    setFieldValue(FIELD.SITES, sites, true);
  };

  const changeAllSites = ({ group, value }: { group: Record<string, boolean>; value: boolean }) => {
    const updatedSites: Record<string, boolean> = {};

    Object.keys(group).forEach((site) => (updatedSites[site] = value));
    setValues({ ...values, sites: updatedSites as typeof values.sites });
  };

  const checkboxProps = (name: keyof FormFields, label: string) => ({
    label,
    onChange: (value: boolean) => changeCheckboxValue({ name, value }),
    state: values[name] ? CheckboxState.CHECKED : CheckboxState.DEFAULT,
  });

  const changeDateValue = ({ name, value }: ChangeDateValue): void => {
    setFieldValue(name, value);
  };

  const getEndDateMinDate = () => {
    // end date is a day after start date or current date
    const offsetDay = 1;
    const date = values.startDate ? new Date(values.startDate) : new Date();
    date.setDate(date.getDate() + offsetDay);
    return date;
  };

  const isFieldDisabled = (fieldName: string) => {
    return disabled || (disabledFields && disabledFields[fieldName]);
  };

  const euroCountrySelected = Object.entries(values.sites).find(
    ([site, selected]) => selected && EURO_SITES.includes(site as Country),
  );

  return (
    <form onSubmit={handleSubmit} className="promocode-form">
      <div className="promocode-form__header">{header}</div>
      <div className="promocode-form__content">
        <Text tag="h1" variant={TextVariant.HEADING_1}>
          {TEXT.PROMO_INFO}
        </Text>
        <div className="promocode-form__form">
          <div className="promocode-form__column">
            <CheckboxGroup
              checkboxList={SITE_CHECKBOX}
              error={errors.sites as unknown as string}
              groupLabel={TEXT.SITES_LBL}
              groupValues={values.sites}
              touched={touched.sites as unknown as boolean}
              onFieldChange={({ name, value }: { name: string; value: boolean }) =>
                changeSitesValue({ name, value, sites: values.sites })
              }
              onGroupChange={(groupCheckValue: boolean) =>
                changeAllSites({ group: values.sites, value: groupCheckValue })
              }
              disabled={isFieldDisabled(FIELD.SITES)}
            />
            <Match>
              <Case when={featureFlags.SHOP_PROMOS || isShopForm(values)}>
                <RadioGroup
                  value={values[FIELD.TYPE]}
                  onChange={(value: string) => changeFieldValue({ name: FIELD.TYPE, value })}
                >
                  <Radio
                    label="Box"
                    value={PROMOCODE_CREATION_TYPES.box}
                    disabled={disabled || isFieldDisabled(FIELD.TYPE)}
                  />
                  <Radio
                    label="Shop"
                    value={PROMOCODE_CREATION_TYPES.shop}
                    disabled={disabled || isFieldDisabled(FIELD.TYPE)}
                  />
                </RadioGroup>
              </Case>
            </Match>
            <Match>
              <Case when={isBoxForm(values)}>
                <CheckboxGroup
                  checkboxList={FREQUENCY_CHECKBOX}
                  error={errors.frequency as unknown as string}
                  groupLabel={TEXT.TYPE_OF_USERS_LBL}
                  groupValues={values.frequency}
                  touched={touched.frequency as unknown as boolean}
                  onFieldChange={({ name, value }: { name: string; value: boolean }) =>
                    changeFrequencyValue({ name, value, frequency: values.frequency })
                  }
                  onGroupChange={(groupCheckValue: boolean) =>
                    changeAllFrequency({ group: values.frequency, value: groupCheckValue })
                  }
                  disabled={isFieldDisabled(FIELD.FREQUENCY)}
                />
              </Case>
            </Match>

            <Match>
              <Case when={featureFlags.APPLICABLE_BOXES && isBoxForm(values)}>
                <Field label={TEXT.APPLICABLE_ORDERS_LBL} description={TEXT.APPLICABLE_ORDERS_TOOLTIP}>
                  <Input
                    {...inputProps(FIELD.APPLICABLE_ORDERS, TEXT.APPLICABLE_ORDERS_PH, "number")}
                    disabled={disabled}
                  />
                </Field>
              </Case>
            </Match>
            <Field label={TEXT.TOTAL_USES_LBL}>
              <Input {...inputProps(FIELD.TOTAL_USES, TEXT.TOTAL_USES_PH, "number")} disabled={disabled} />
            </Field>

            <div className="promocode-form__group">
              <div className="promocode-form__options">
                <Checkbox {...checkboxProps(FIELD.FIRST_ORDER, TEXT.FIRST_ORDER_LBL)} disabled={disabled} />
                <Checkbox {...checkboxProps(FIELD.ADD_GIFT, TEXT.ADD_GIFT_LBL)} disabled={disabled} />
                <Checkbox {...checkboxProps(FIELD.IS_ACTIVE, TEXT.IS_ACTIVE_LBL)} disabled={disabled} />
              </div>
            </div>
            <div className="promocode-form__group">
              <Field label={TEXT.START_DATE_LBL}>
                <DatePicker
                  className="promocode-form__full-date-picker"
                  value={values[FIELD.START_DATE]}
                  minDate={new Date()}
                  onChange={(e) => changeDateValue({ name: FIELD.START_DATE, value: e })}
                  readOnly={isFieldDisabled(FIELD.START_DATE)}
                />
              </Field>
              <Field label={TEXT.END_DATE_LBL}>
                <DatePicker
                  className="promocode-form__full-date-picker"
                  value={values[FIELD.END_DATE]}
                  minDate={getEndDateMinDate()}
                  onChange={(e) => changeDateValue({ name: FIELD.END_DATE, value: e })}
                  error={errors.endDate}
                  readOnly={isFieldDisabled(FIELD.END_DATE)}
                />
              </Field>
            </div>
          </div>
          <Divider className="promocode-form__divider" />
          <div className="promocode-form__column">
            <PromocodeIdentField values={values} changeFieldValue={changeFieldValue} inputProps={inputProps} />
            <Field label={TEXT.DESCRIPTION_LBL} description={TEXT.DESCRIPTION_TOOLTIP}>
              <Input {...inputProps(FIELD.DESC, TEXT.DESCRIPTION_PH)} disabled={disabled} />
            </Field>
            <Match>
              <Case when={isBoxForm(values)}>
                {euroCountrySelected && (
                  <Field label={TEXT.AMOUNT_EUR_LABEL}>
                    <Input
                      {...inputProps(FIELD.AMOUNT_EUR, TEXT.AMOUNT_EUR_PLACEHOLDER, "number")}
                      disabled={disabled}
                    />
                  </Field>
                )}
                {values.sites["GB"] && (
                  <Field label={TEXT.AMOUNT_GBP_LABEL}>
                    <Input
                      {...inputProps(FIELD.AMOUNT_GBP, TEXT.AMOUNT_GBP_PLACEHOLDER, "number")}
                      disabled={disabled}
                    />
                  </Field>
                )}
                {values.sites["SE"] && (
                  <Field label={TEXT.AMOUNT_SEK_LABEL}>
                    <Input
                      {...inputProps(FIELD.AMOUNT_SEK, TEXT.AMOUNT_SEK_PLACEHOLDER, "number")}
                      disabled={disabled}
                    />
                  </Field>
                )}
                {values.sites["CH"] && (
                  <Field label={TEXT.AMOUNT_CHF_LABEL}>
                    <Input
                      {...inputProps(FIELD.AMOUNT_CHF, TEXT.AMOUNT_CHF_PLACEHOLDER, "number")}
                      disabled={disabled}
                    />
                  </Field>
                )}
              </Case>
            </Match>
            <Field label={TEXT.PCT_LBL}>
              <Input {...inputProps(FIELD.PCT, TEXT.PCT_PH, "number")} disabled={disabled} />
            </Field>
            <Match>
              <Case when={isBoxForm(values)}>
                <Field label={TEXT.SERVICE_PCT_LBL}>
                  <Input {...inputProps(FIELD.SERVICE_PCT, TEXT.SERVICE_PCT_PH, "number")} disabled={disabled} />
                </Field>
              </Case>
            </Match>
          </div>
        </div>
      </div>
    </form>
  );
};

export default PromocodeForm;
