import { Brand } from "../shared/Brand";

export type PromocodeSites = Brand<string[], "promocode/PromocodeSites">;

export const enum Country {
  ES = "ES",
  FR = "FR",
  IT = "IT",
  BE = "BE",
  LU = "LU",
  PT = "PT",
  DE = "DE",
  AT = "AT",
  NL = "NL",
  GB = "GB",
  SE = "SE",
  CH = "CH",
}

export const EURO_SITES: Country[] = [
  Country.ES,
  Country.FR,
  Country.IT,
  Country.BE,
  Country.PT,
  Country.DE,
  Country.AT,
  Country.NL,
];

export const AVAILABLE_SITES = [...EURO_SITES, Country.GB, Country.SE, Country.CH];

export const valuePromocodeSites = (sites: { [x: string]: boolean }): PromocodeSites => {
  if (sites.ALL) {
    return [...AVAILABLE_SITES] as PromocodeSites;
  }

  const siteList = Object.keys(sites);
  const activeSites = siteList.filter((site) => sites[site]);

  return (activeSites.length === AVAILABLE_SITES.length ? ["ALL"] : activeSites) as PromocodeSites;
};

export const countriesToSites = (countries: string[]): PromocodeSites => {
  return countries.filter((country) => country !== Country.LU) as PromocodeSites;
};

export const sitesToCountries = (sites: PromocodeSites): string[] => {
  const hasBE = sites.includes(Country.BE);
  return hasBE ? [...sites, Country.LU] : sites;
};
