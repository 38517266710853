import { fromPrimitive, PrimitivePromocode } from "@domain/promocode/Promocode";
import { PromocodeRepository } from "@domain/promocode/PromocodeRepository";

type CreatePromocodeUseCase = (primitivePromocode: PrimitivePromocode) => Promise<void>;

const CreatePromocode =
  (repository: PromocodeRepository): CreatePromocodeUseCase =>
  async (primitivePromocode) => {
    try {
      const promocode = fromPrimitive(primitivePromocode);
      await repository.createPromocode(promocode);
    } catch (error) {
      // @TODO: ERROR
      return Promise.reject();
    }
  };

export { CreatePromocode };
