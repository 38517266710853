import * as Yup from "yup";

type Items = { [key: string]: string };
type CheckboxGroupYupObjet = { [key: string]: Yup.BooleanSchema };

export const getCheckboxGroupYupObject = (items: Items): Yup.ObjectSchema<CheckboxGroupYupObjet> => {
  return Yup.object(
    Object.keys(items).reduce((object: CheckboxGroupYupObjet, site) => {
      object[site] = Yup.boolean();
      return object;
    }, {}),
  );
};
