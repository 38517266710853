import { fromPrimitive, PrimitiveBulk } from "@domain/promocode/Bulk";
import { PromocodeRepository } from "@domain/promocode/PromocodeRepository";

type CreateBulkPromocodes = (repository: PromocodeRepository) => (bulk: PrimitiveBulk) => Promise<void>;

export const CreateBulkPromocodes: CreateBulkPromocodes = (repository) => async (primitiveBulk) => {
  try {
    const bulk = fromPrimitive(primitiveBulk);
    await repository.createBulk(bulk);
  } catch (error) {
    // @TODO: ERROR
    return Promise.reject();
  }
};
