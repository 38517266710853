import { Brand } from "./Brand";

export type ValuePercentage = Brand<number, "shared/percentage">;

class InvalidPercentageError extends Error {
  constructor() {
    super("The percentage value expected is between 0 and 100");
  }
}

export const valuePercentage = (value: number): ValuePercentage => {
  if (value < 0 || value > 100) {
    throw new InvalidPercentageError();
  }
  return value as ValuePercentage;
};
