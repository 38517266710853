import React, { useEffect } from "react";
import { NotificationVariant } from "@lookiero/react-ui-kit";
import { MessageErrorEvent } from "@core/shared/MessageErrorEvent";
import { useEventBus } from "@hooks/useEventBus";
import { useToastService } from "@layouts/ToastProvider/useToast";
import TEXT from "./ToastErrors.intl.json";

const ToastErrors: React.FC = () => {
  const eventBus = useEventBus();
  const { push } = useToastService();

  useEffect(() => {
    return eventBus.subscribe(MessageErrorEvent.name, (event: { name: string; payload: { message: string } }) => {
      push(<>{TEXT[event.payload.message as keyof typeof TEXT]}</>, {
        time: 10 * 1000,
        variant: NotificationVariant.DESTRUCTIVE,
      });
    });
  }, [eventBus, push]);

  return null;
};

export default ToastErrors;
