import Show from "@atoms/Show/Show";
import MainLayout from "@layouts/MainLayout/MainLayout";
import { Loader } from "@lookiero/react-ui-kit";
import { SIDEMENU_ROUTE_LIST } from "@organisms/SideMenu/SideMenu.config";
import { BulkStatusProvider } from "@promocodes/components/PromocodeBulkStatus/useBulkStatus";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { useAuth } from "@hooks/useAuth";
import { ROUTES, RouteType } from "./Routes.config";

const centerSpinner: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const RouteNotFound: React.FC = () => <Redirect to={{ pathname: ROUTES.NOTFOUND.path }} />;

const LoadingAuth: React.FC = () => (
  <div style={centerSpinner}>
    <Loader />
  </div>
);

export const Routes: React.FC = () => {
  const { hasPromocodesWriteRole, loggedIn } = useAuth();

  const promocodesRoutes = hasPromocodesWriteRole()
    ? [ROUTES.PROMOCODES, ROUTES.PROMOCODES_ADD, ROUTES.PROMOCODES_DETAIL, ROUTES.PROMOCODES_EDIT]
    : [ROUTES.PROMOCODES, ROUTES.PROMOCODES_DETAIL];

  const renderRoute = (route: RouteType) => {
    const { component: Component, secured, ...rest } = route;
    return (
      <Route key={route.path} {...rest}>
        {!secured || (secured && loggedIn) ? <Component /> : <Redirect to={{ pathname: ROUTES.LOGIN.path }} />}
      </Route>
    );
  };

  return (
    <Show when={loggedIn !== null} fallback={<LoadingAuth />}>
      <Switch>
        <Redirect from={ROUTES.HOME.path} to={SIDEMENU_ROUTE_LIST[0].path} exact />
        {[ROUTES.LOGIN].map(renderRoute)}
        <Route path="*">
          <MainLayout>
            <Switch>
              {[ROUTES.HOME, ROUTES.NOTFOUND].map(renderRoute)}
              <Route path={ROUTES.PROMOCODES.path}>
                <BulkStatusProvider>
                  <Switch>
                    {promocodesRoutes.map(renderRoute)}
                    <RouteNotFound />
                  </Switch>
                </BulkStatusProvider>
              </Route>
              <RouteNotFound />
            </Switch>
          </MainLayout>
        </Route>
      </Switch>
    </Show>
  );
};
