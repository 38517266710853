import { Device, DiscountAmounts, fromPrimitive, Promocode, Service } from "@domain/promocode";
import { toString } from "@domain/shared/Date";
import { arrayStringToBooleanObject } from "@utils/arrayStringToBooleanObject";
import { toFrequencyObject } from "@domain/promocode/PromocodeFrequency";
import { countriesToSites, sitesToCountries } from "@domain/promocode/PromocodeSites";

export type ApiPromocode = {
  id?: number;
  name: string;
  description: string;
  discount_amounts: DiscountAmounts;
  discount_pct: number;
  discount_service_pct: number;
  add_gift: boolean;
  active: boolean;
  total_uses?: number;
  current_uses?: number;
  applicable_orders?: number;
  first_order_only: boolean;
  frequency?: string[];
  start_date?: string;
  finish_date?: string;
  countries: string[];
  devices: Device[];
  services: Service[];
};

export type ApiPromocodeList = {
  content: ApiPromocode[];
  number: number;
  number_of_elements: number;
  size: number;
  total_elements: number;
  total_pages: number;
};

export const promocodeToApi = (promocode: Promocode): ApiPromocode => {
  return {
    name: promocode.name,
    description: promocode.description,
    discount_amounts: promocode.discountAmounts,
    discount_pct: promocode.discountPct,
    discount_service_pct: promocode.discountServicePct,
    add_gift: promocode.addGift,
    active: promocode.isActive,
    applicable_orders: promocode.applicableOrders,
    total_uses: promocode.totalUses,
    first_order_only: promocode.firstOrderOnly,
    frequency: promocode.frequency,
    start_date: toString(promocode.startDate),
    finish_date: toString(promocode.endDate),
    countries: sitesToCountries(promocode.sites),
    devices: ["WEB", "APP"], // TODO
    services: promocode.services,
  };
};

export const apiToPromocode = ({
  id,
  name,
  description,
  discount_amounts: discountAmounts,
  discount_pct: discountPct,
  discount_service_pct: discountServicePct,
  countries,
  add_gift: addGift,
  active: isActive,
  total_uses: totalUses,
  applicable_orders: applicableOrders,
  current_uses: currentUses,
  first_order_only: firstOrderOnly,
  frequency,
  devices,
  start_date: startDate,
  finish_date: endDate,
  services,
}: ApiPromocode): Promocode =>
  fromPrimitive({
    key: id,
    name: name.replaceAll(" ", "_"),
    description,
    discountAmounts,
    discountPct,
    discountServicePct,
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
    sites: arrayStringToBooleanObject(countriesToSites(countries)),
    addGift,
    isActive,
    totalUses,
    applicableOrders,
    currentUses,
    firstOrderOnly,
    frequency: toFrequencyObject(frequency),
    devices,
    services: services || ["BOX"],
  });
