import { Promocode, PromocodeRepository } from "@domain/promocode";

export const FindPromocode =
  (promocodeRepository: PromocodeRepository) =>
  ({
    page = 0,
    promocode = "",
  }: {
    page: number;
    promocode: string;
  }): Promise<{
    totalElements: number;
    promocodes: Promocode[];
  }> => {
    return promocodeRepository.find({ page, promocode });
  };
