type Memorize<T extends (...args: any[]) => any> = {
  has: (args: Parameters<T>) => boolean;
  save: (args: Parameters<T>, result: ReturnType<T>) => ReturnType<T>;
  get: (args: Parameters<T>) => ReturnType<T>;
};

const defaultMemorize = <T extends (...args: any[]) => any>(): Memorize<T> => {
  let cache: ReturnType<T> | null = null;

  return {
    has: () => Boolean(cache),
    save: (args, result) => {
      cache = result;
      return result;
    },
    get: () => cache as ReturnType<T>,
  };
};

export const memorize = <T extends (...args: any[]) => any>(fn: T, memorize: Memorize<T> = defaultMemorize()) => {
  return (...args: Parameters<T>): ReturnType<T> =>
    memorize.has(args) ? memorize.get(args) : memorize.save(args, fn(...args));
};
