import "./App.css";
import "./_components/_tokens";
import "@lookiero/react-ui-kit/lib/styles/styles.css";
import { eventBus } from "@infrastructure/shared/EventBus/EventBus";
import { Router, Routes } from "@router";
import ToastProvider from "@layouts/ToastProvider/ToastProvider";
import { EventBusProvider } from "@hooks/useEventBus";
import ToastErrors from "@organisms/ToastErrors/ToastErrors";
import { Environment } from "../core/domain/environment/Environment";
import { EnvironmentProvider } from "./_hooks/useEnvironment";
import { AuthProvider } from "@hooks/useAuth";

const App: React.FC<{ environment: Environment }> = ({ environment }) => {
  return (
    <EnvironmentProvider value={environment}>
      <EventBusProvider value={eventBus}>
        <ToastProvider>
          <AuthProvider>
            <Router>
              <Routes />
            </Router>
          </AuthProvider>
          <ToastErrors />
        </ToastProvider>
      </EventBusProvider>
    </EnvironmentProvider>
  );
};

export default App;
