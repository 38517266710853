import { CreatePromocode } from "@application/promocode/CreatePromocode";
import { FindPromocode } from "@application/promocode/FindPromocodes";
import { CreateBulkPromocodes } from "@application/promocode/CreateBulkPromocodes";
import { EditPromocode } from "@application/promocode/EditPromocode";
import { BulkStatusPromocode } from "@application/promocode/BulkStatusPromocode";
import { GetPromocode } from "@application/promocode/GetPromocode";
import { ExportPromocode } from "@application/promocode/ExportPromocode";
import { ApiPromocodeRepository } from "@infrastructure/promocode/ApiPromocodeRepository";

import Container, { toFakeImport } from "../shared/Container";

const PromocodeApplication = Container.of({
  create: toFakeImport(CreatePromocode(ApiPromocodeRepository)),
  edit: toFakeImport(EditPromocode(ApiPromocodeRepository)),
  createBulk: toFakeImport(CreateBulkPromocodes(ApiPromocodeRepository)),
  find: toFakeImport(FindPromocode(ApiPromocodeRepository)),
  bulkStatus: toFakeImport(BulkStatusPromocode(ApiPromocodeRepository)),
  get: toFakeImport(GetPromocode(ApiPromocodeRepository)),
  export: toFakeImport(ExportPromocode(ApiPromocodeRepository.download, () => new Date())),
});

export { PromocodeApplication };
