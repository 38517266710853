import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, ButtonVariant, Field, Icon, IconVariant, Input, Link } from "@lookiero/react-ui-kit";

import { ROUTES, useNavigator } from "@router";
import { emptyFn } from "@utils/emptyFn";
import { PromocodeApplication } from "@workflow";
import { Promocode } from "@domain/promocode";

import PromocodeForm from "../../components/PromocodeForm/PromocodeForm";
import { FIELD } from "../../components/PromocodeForm/PromocodeForm.schema";
import TEXT_FORM from "../../components/PromocodeForm/PromocodeForm.intl.json";
import PromocodeBulkStatus from "../../components/PromocodeBulkStatus/PromocodeBulkStatus";
import { promocodeToFormValues } from "../../components/PromocodeForm/utils/formValuesParser";

import TEXT from "./PromocodeDetail.intl.json";
import "./PromocodeDetail.css";

const PromocodeDetail: React.FC = () => {
  const [promocode, setPromocode] = useState<Promocode | null>(null);
  const { promocodeKey } = useParams<{ promocodeKey: string }>();
  const navigator = useNavigator();

  useEffect(() => {
    (async () => {
      const getPromocode = await PromocodeApplication.get("get");

      getPromocode(parseInt(promocodeKey))
        .then(setPromocode)
        .catch(() => {
          navigator.goTo(ROUTES.PROMOCODES);
        });
    })();
  }, [promocodeKey, navigator]);

  return promocode ? (
    <div className="promocode-detail">
      <PromocodeBulkStatus />
      <PromocodeForm
        header={
          <Link to={ROUTES.PROMOCODES.path}>
            <Button variant={ButtonVariant.PRIMARY} className="promocode-detail__cancel">
              <Icon variant={IconVariant.ARROW_LEFT} />
              {TEXT.BACK_BTN}
            </Button>
          </Link>
        }
        defaultValues={promocodeToFormValues(promocode)}
        promocodeIdentField={({ inputProps }) => (
          <Field label={TEXT_FORM.PROMOTION_LBL}>
            <Input {...inputProps(FIELD.NAME, TEXT_FORM.PROMOTION_PH)} disabled />
          </Field>
        )}
        onSubmit={emptyFn}
        disabled
      />
    </div>
  ) : null;
};

export default PromocodeDetail;
