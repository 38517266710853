import React from "react";

import { Text, TextVariant, Icon, IconVariant } from "@lookiero/react-ui-kit";

import "./PromocodeEmpty.css";

const PromocodeEmpty: React.FC = () => {
  return (
    <div className="empty-promocodes__content">
      <section className="empty-promocodes__section">
        <div className="empty-promocodes__icon">
          <Icon variant={IconVariant.DASHBOARD} className="icon__detail" />
        </div>
        <div className="empty-promocodes__title">
          <Text variant={TextVariant.HEADING_1}>This is where you will manage the promocodes</Text>
        </div>
        <div className="empty-promocodes__body">
          <Text variant={TextVariant.BODY}>You can create a new promocode, view it and edit it.</Text>
        </div>
      </section>
    </div>
  );
};

export default PromocodeEmpty;
