import { FC } from "react";
import classNames from "classnames";
import { Icon, IconVariant, Text, TextVariant } from "@lookiero/react-ui-kit";

import "./SideMenu.css";

type SideMenuItemProps = {
  active?: boolean;
  icon: { variant: IconVariant; flip?: boolean; noFill?: boolean };
  text: string;
  textVariant?: TextVariant;
  onClick: () => void;
};

const SideMenuItem: FC<SideMenuItemProps> = ({
  active = false,
  icon,
  text,
  textVariant = TextVariant.BODY_BOLD,
  onClick,
}) => {
  return (
    <div className={classNames("side-menu__item", { active })} onClick={onClick}>
      <Icon
        variant={icon.variant}
        className={classNames("side-menu__icon", { flip: icon.flip }, { "no-fill": icon.noFill })}
      />
      <Text variant={textVariant}>{text}</Text>
    </div>
  );
};

export default SideMenuItem;
