import { AuthStorageRepository } from "@domain/auth";
import { EventBus } from "@domain/shared/EventBus";

import { unauthorized } from "./Unauthorized";
import { UnauthorizedErrorEvent } from "./UnauthorizedErrorEvent";

export const UnauthorizedErrorEventHandler = (
  eventBus: EventBus,
  AuthStorageRepository: AuthStorageRepository,
): void => {
  eventBus.subscribe(UnauthorizedErrorEvent.name, unauthorized(eventBus, AuthStorageRepository));
};
