import { ApiAuthStorageRepository } from "../auth/ApiAuthStorageRepository";
import { getEnvironment } from "../environment/HttpEnvironmentRepository";
import { EventSourceClient } from "../streaming/EventSourceClient";

// TODO: Move to factory
const PromocodeClient = EventSourceClient.create({
  baseUrl: () => getEnvironment().then((environment) => environment.endpoints.PROMOCODE_API_URL),
  middleware: (config = {}) => {
    const token = ApiAuthStorageRepository.get();

    const extendedConfig = {
      ...config,
      ...(token && {
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      }),
    };

    return extendedConfig;
  },
});

export { PromocodeClient };
