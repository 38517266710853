import classNames from "classnames";
import "./Divider.css";

type DividerProps = {
  className: string;
};
const Divider = ({ className }: DividerProps): JSX.Element => {
  return <div className={classNames("divider", className)} />;
};

export default Divider;
