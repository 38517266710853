import ReactDOM from "react-dom";
import "@lookiero/react-ui-kit/lib/styles/styles.css";
import * as serviceWorker from "./serviceWorker";
import App from "./ui/App";
import { bootstrap } from "./bootstrap";
import "./consumers";
import "./ui/index.css";

const run = async () => {
  const { environment } = await bootstrap();

  ReactDOM.render(<App environment={environment} />, document.getElementById("root"));
};

run();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
