export type FrequencyList = string[];
export type FrequencyObject = { onDemand?: boolean; subscriber?: boolean };

enum FREQUENCY {
  ON_DEMAND = "0",
  EACH_MONTH = "1",
  EVERY_TWO_MONTHS = "2",
  EVERY_THREE_MONTHS = "3",
}

// Get values to send to API
export const toFrequencyList = (frequency: FrequencyObject = {}): FrequencyList => {
  const frequencies: string[] = [];

  if (frequency.onDemand) {
    frequencies.push(FREQUENCY.ON_DEMAND);
  }

  if (frequency.subscriber) {
    frequencies.push(FREQUENCY.EACH_MONTH, FREQUENCY.EVERY_TWO_MONTHS, FREQUENCY.EVERY_THREE_MONTHS);
  }

  return frequencies;
};

// Get values to use in form
export const toFrequencyObject = (frequencies: FrequencyList = []): FrequencyObject => {
  return frequencies.reduce(
    (frequencies: FrequencyObject, frequency: string) => {
      const freqNumber = Number(frequency);
      if (freqNumber === 0) {
        frequencies.onDemand = true;
      } else if (freqNumber > 0) {
        frequencies.subscriber = true;
      }
      return frequencies;
    },
    {
      onDemand: false,
      subscriber: false,
    },
  );
};
