import { Brand } from "../shared/Brand";

export type PromocodeName = Brand<string, "promocode/PromocodeName">;

export class SpacingPromocodeNameError extends Error {
  constructor() {
    super("The promocode name cannot have any spaces");
  }
}

export const valuePromocodeName = (value: string): PromocodeName => {
  if (value.includes(" ")) {
    throw new SpacingPromocodeNameError();
  }
  return value as PromocodeName;
};
