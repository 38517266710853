import { createContext, useContext } from "react";

export const RadioGroupContext = createContext<{
  value: string;
  onChange: (value: string) => void;
} | null>(null);

export const useRadioGroup = () => {
  const context = useContext(RadioGroupContext);

  if (context === null) {
    throw new Error("useRadioGroupContext must be used within a RadioGroup");
  }

  return context;
};
