import { fetchEventSource, FetchEventSourceInit } from "@microsoft/fetch-event-source";
import { Connection } from "@domain/shared/EventSource";
import { RetryError, FatalError } from "./EventSourceErrors";

type MiddlewareFn = (config: FetchEventSourceInit) => FetchEventSourceInit;

type ErrorHandlerFn = (error: Error) => void;

type EventSourceCallbacks<R> = {
  onMessage: (data: R) => void;
};

type MessageParser<T, R> = (data: T) => R;

const isFatalError = (status: number): boolean => {
  return status >= 400 && status < 500 && status !== 429;
};

type OnOpen = FetchEventSourceInit["onopen"];

const onOpen: OnOpen = (res) => {
  if (res.ok) {
    return Promise.resolve();
  } else if (isFatalError(res.status)) {
    throw new FatalError();
  } else {
    throw new RetryError();
  }
};

type OnClose = FetchEventSourceInit["onclose"];

const onClose: OnClose = () => {
  throw new RetryError();
};

type OnError = (errorHandler?: ErrorHandlerFn) => FetchEventSourceInit["onerror"];

const onError: OnError = (errorHandler) => (err) => {
  if (err instanceof FatalError) {
    if (errorHandler) errorHandler(err);
    throw err;
  }
};

type OnMessage = (
  callback: EventSourceCallbacks<any>["onMessage"], // eslint-disable-line @typescript-eslint/no-explicit-any
  messageParser?: MessageParser<any, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
) => FetchEventSourceInit["onmessage"];

const onMessage: OnMessage = (callback, messageParser) => (event) => {
  callback(messageParser ? messageParser(event.data) : event.data);
};

type ConnectFn = <T, R>(
  path: string,
  callbacks: EventSourceCallbacks<R>,
  config?: FetchEventSourceInit,
  middleware?: MiddlewareFn,
  messageParser?: MessageParser<T, R>,
  errorHandler?: ErrorHandlerFn,
) => Promise<Connection>;

const connect: ConnectFn = async (path, callbacks, config = {}, middleware, messageParser, errorHandler) => {
  const extendedConfig = middleware ? middleware(config) : config;
  const extendedCallbacks = {
    onopen: onOpen,
    onclose: onClose,
    onerror: onError(errorHandler),
    onmessage: onMessage(callbacks.onMessage, messageParser),
  };
  const controller = new AbortController();
  fetchEventSource(path, { signal: controller.signal, openWhenHidden: true, ...extendedConfig, ...extendedCallbacks });
  return Promise.resolve({ close: () => controller.abort() });
};

type CreateClient = (props: {
  baseUrl: () => Promise<string>;
  config?: FetchEventSourceInit;
  middleware?: MiddlewareFn;
  errorHandler?: ErrorHandlerFn;
}) => {
  connect: <T, R>(
    path: string,
    callbacks: EventSourceCallbacks<R>,
    messageParser?: MessageParser<T, R>,
  ) => Promise<Connection>;
};

const create: CreateClient = ({ baseUrl, config, middleware, errorHandler }) => {
  return {
    connect: async (path, callbacks, messageParser) => {
      return await connect((await baseUrl()) + path, callbacks, config, middleware, messageParser, errorHandler);
    },
  };
};

const EventSourceClient = { connect, create };

export { EventSourceClient };
