import { DependencyList, useEffect } from "react";

export function useDebounceEffect(effect: () => void, time: number, deps?: DependencyList | undefined): void {
  useEffect(() => {
    const timeoutId = setTimeout(effect, time);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
