import { fromPrimitive, PrimitivePromocode } from "@domain/promocode/Promocode";
import { PromocodeRepository } from "@domain/promocode/PromocodeRepository";

type EditPromocodeUseCase = (key: number, primitivePromocode: PrimitivePromocode) => Promise<void>;

const EditPromocode =
  (repository: PromocodeRepository): EditPromocodeUseCase =>
  async (key, primitivePromocode) => {
    try {
      const promocode = fromPrimitive(primitivePromocode);
      await repository.editPromocode(key, promocode);
    } catch (error) {
      // @TODO: ERROR
      return Promise.reject();
    }
  };

export { EditPromocode };
