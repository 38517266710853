import React from "react";
import classNames from "classnames";
import { Button, Icon, IconVariant } from "@lookiero/react-ui-kit";
import "./Pagination.css";

export type PaginationProps = {
  itemsInPage?: number;
  totalItems: number;
  page: number;
  offset?: number;
  onChangePage: (page: number) => void;
};

type ButtonPaginationProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  noClickable?: boolean;
  active?: boolean;
};

const inRange = (min: number, max: number, value: number) => Math.min(max, Math.max(min, value));

const range = (min: number, max: number) => Array.from({ length: max + 1 - min }, (s, i) => i + min);

const generateRangeOffset = (page: number, totalPages: number, offset: number) => {
  const isFirstPage = page <= 1;
  const isLastPage = page >= totalPages;

  const from = isFirstPage ? 1 : page - offset;
  const to = isLastPage ? totalPages : page + offset;

  return range(from, to);
};

const calcItemsToShow = (itemsInPage: number, page: number, totalItems: number) => ({
  from: itemsInPage * (page - 1) + 1,
  to: itemsInPage * page > totalItems ? totalItems : itemsInPage * page,
});

const ButtonPag: React.FC<ButtonPaginationProps> = ({ onClick, disabled, children, noClickable, active }) => (
  <Button
    className={classNames("pagination__page", {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "pagination__page--noaction": noClickable,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "pagination__page--active": active,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
);

const Pagination: React.FC<PaginationProps> = ({
  itemsInPage = 20,
  totalItems,
  page = 1,
  onChangePage,
  offset = 1,
}) => {
  const totalPages = Math.ceil(totalItems / itemsInPage);
  const safePage = inRange(1, totalPages, page);
  const range = generateRangeOffset(safePage, totalPages, offset);
  const { from, to } = calcItemsToShow(itemsInPage, safePage, totalItems);

  const handleClickButton = (newPage: number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    onChangePage(newPage);
  };

  return (
    <div className="pagination">
      <div className="text text--body pagination__info">
        Showing {from}-{to} of {totalItems} items
      </div>
      <div className="pagination__group">
        <ButtonPag onClick={handleClickButton(safePage - 1)} disabled={1 === safePage}>
          <Icon className="pagination__page--icon" variant={IconVariant.CHEVRON_LEFT} />
        </ButtonPag>
        {range.includes(1) ? null : <ButtonPag onClick={handleClickButton(1)}>1</ButtonPag>}
        {range.includes(2) ? null : range[0] - 1 === 2 ? (
          <ButtonPag onClick={handleClickButton(2)}>2</ButtonPag>
        ) : (
          <ButtonPag noClickable>...</ButtonPag>
        )}
        {range.map((number) => (
          <ButtonPag key={number} active={number === safePage} onClick={handleClickButton(number)}>
            {number}
          </ButtonPag>
        ))}
        {range.includes(totalPages - 1) ? null : range[range.length - 1] + 1 === totalPages - 1 ? (
          <ButtonPag onClick={handleClickButton(totalPages - 1)}>{totalPages - 1}</ButtonPag>
        ) : (
          <ButtonPag noClickable>...</ButtonPag>
        )}
        {range.includes(totalPages) ? null : (
          <ButtonPag onClick={handleClickButton(totalPages)}>{totalPages}</ButtonPag>
        )}
        <ButtonPag onClick={handleClickButton(page + 1)} disabled={totalPages === safePage}>
          <Icon className="pagination__page--icon" variant={IconVariant.CHEVRON_RIGHT} />
        </ButtonPag>
      </div>
    </div>
  );
};

export default Pagination;
