import { BulkStatusMessage, ServerErrorMessage, ServerPayloadMessage } from "@domain/promocode/BulkStatus";
import { isBackendError } from "@domain/promocode/Error";
import { Bulk } from "@domain/promocode/Bulk";
import { toString } from "@domain/shared/Date";
import { sitesToCountries } from "@domain/promocode/PromocodeSites";
import { ApiPromocode } from "./Promocode";

type ApiBulk = {
  total: number;
  prefix: string;
  length: number;
  promotion_data: Omit<ApiPromocode, "name">;
};

export const bulkStatusParser = (data: string): BulkStatusMessage => {
  const parsedData = JSON.parse(data);

  if (isBackendError(parsedData)) {
    const error = parsedData as ServerErrorMessage;
    return { error: { code: error.error_code, message: error.error_message } };
  }

  const payload = parsedData as ServerPayloadMessage;
  return { payload: { actual: payload.actual, total: payload.total } };
};

export const bulkToApi = (bulk: Bulk): ApiBulk => {
  return {
    prefix: bulk.prefix,
    length: bulk.length,
    total: bulk.total,
    promotion_data: {
      description: bulk.promocodeInfo.description,
      discount_amounts: bulk.promocodeInfo.discountAmounts,
      discount_pct: bulk.promocodeInfo.discountPct,
      discount_service_pct: bulk.promocodeInfo.discountServicePct,
      add_gift: bulk.promocodeInfo.addGift,
      active: bulk.promocodeInfo.isActive,
      applicable_orders: bulk.promocodeInfo.applicableOrders,
      total_uses: bulk.promocodeInfo.totalUses,
      first_order_only: bulk.promocodeInfo.firstOrderOnly,
      frequency: bulk.promocodeInfo.frequency,
      start_date: toString(bulk.promocodeInfo.startDate),
      finish_date: toString(bulk.promocodeInfo.endDate),
      countries: sitesToCountries(bulk.promocodeInfo.sites),
      devices: ["WEB", "APP"], // TODO
      services: bulk.promocodeInfo.services,
    },
  };
};
