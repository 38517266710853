import React, { useMemo } from "react";
import classNames from "classnames";
import "./Table.css";

type ColumnDefinition = {
  key: string;
  content: React.ReactNode;
  className?: string;
};

type TableHeader = ColumnDefinition[];

type RenderRow = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: React.FC<any>;
};

type TableHeadProps = { head: TableHeader };

type Data = { [key: string]: unknown } & { key: string };

type TableBodyProps = {
  head: TableHeader;
  rows: Data[];
  renderRow: RenderRow;
  onClick?: (row: Data) => void;
};

type TableProps = {
  head: TableHeader;
  rows: Data[];
  renderRow?: RenderRow;
  className?: string;
  onClick?: (row: Data) => void;
};

const TableHead: React.FC<TableHeadProps> = ({ head }) => (
  <thead className="table__head">
    <tr role="row" className="table__row">
      {head.map((definition) => (
        <th
          className={classNames("table__cell", definition.className)}
          key={definition.key as string}
          role="columnheader"
        >
          {definition.content}
        </th>
      ))}
    </tr>
  </thead>
);

const TableBody: React.FC<TableBodyProps> = ({ head, rows, renderRow = {}, onClick }) => (
  <tbody className="table__body">
    {rows.map(({ key: rowKey, ...row }) => (
      <tr
        key={rowKey}
        role="row"
        className={`table__row ${onClick ? "table__row--clickable" : ""}`}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault();
            onClick({ key: rowKey, ...row });
          }
        }}
      >
        {head.map(({ key, className }) => (
          <td key={key as string} className={classNames("table__cell", className)} role="row">
            {renderRow[key]({ key: rowKey, ...row })}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

const Table: React.FC<TableProps> = ({ head, renderRow = {}, rows, className, onClick, children }) => {
  const renderRowWithDefault = useMemo(() => {
    return head.reduce(
      (acc: RenderRow, { key }) => {
        if ((key as string) in acc) {
          return acc;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        acc[key] = (props: any) => <>{props[key] ?? ""}</>;
        return acc;
      },
      { ...renderRow },
    );
  }, [renderRow, head]) as unknown as RenderRow;

  return (
    <table className={classNames("table", className)} role="table">
      <TableHead head={head} />
      <TableBody head={head} rows={rows} renderRow={renderRowWithDefault} onClick={onClick} />
      {children ? (
        <tfoot>
          <tr>
            <td colSpan={head.length}>{children}</td>
          </tr>
        </tfoot>
      ) : null}
    </table>
  );
};

export default Table;
