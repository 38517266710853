/** https://grw-panel-api.dev.envs.lookiero.tech/ */
/** https://grw-panel-api.prod.envs.lookiero.tech/ */

import { AuthRepository, LoginDTO } from "@domain/auth";
import { PromocodeClient } from "../promocode/PromocodeClient";

const login = async ({ email, password }: { email: string; password: string }): Promise<LoginDTO> => {
  const body = { email, password };
  return PromocodeClient.http.post<{ email: string; password: string }, LoginDTO>("/api/authenticate", body);
};

const ApiAuthRepository: AuthRepository = { login };

export { ApiAuthRepository };
