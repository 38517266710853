import React from "react";
import { useFormik } from "formik";
import { Button, ButtonVariant, Input, Text, TextVariant, Field } from "@lookiero/react-ui-kit";

import { FIELD, LOGIN_SCHEMA } from "@pages/login/Login.schema";
import TEXT from "@pages/login/Login.intl.json";

const DEFAULT_VALUES = {
  [FIELD.EMAIL]: "",
  [FIELD.PASSWORD]: "",
};

type Fields = { [FIELD.EMAIL]: string; [FIELD.PASSWORD]: string };

const LoginForm: React.FC<{
  defaultValues?: Fields;
  onSubmit: (values: Fields) => void;
}> = ({ defaultValues = DEFAULT_VALUES, onSubmit }) => {
  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    initialValues: defaultValues,
    onSubmit,
    validationSchema: LOGIN_SCHEMA,
  });

  const changeFieldValue = ({ name, value }: { name: string; value: string }): void => {
    setFieldValue(name, value);
  };

  return (
    <div className="login">
      <div className="login__container">
        <div className="login__cover">
          <img className="login__cover-image" alt={TEXT.TITLE_IMG} src={`${process.env.PUBLIC_URL}/Cover.png`} />
          <div className="login__cover-text">
            <Text variant={TextVariant.HEADING_1} className="login__text-title">
              {TEXT.TITLE_IMG}
            </Text>
            <Text variant={TextVariant.BODY_SMALL}>{TEXT.SUBTITLE_IMG}</Text>
          </div>
        </div>
        <form data-testid="login-form" className="login__credentials" onSubmit={handleSubmit}>
          <Text variant={TextVariant.HEADING_2} className="login__text-title">
            {TEXT.TITLE}
          </Text>
          <Text variant={TextVariant.BODY_SMALL} className="login__credentials-text">
            {TEXT.SUBTITLE}
          </Text>
          <Field label={TEXT.EMAIL_LABEL} className="login__input">
            <Input
              name={FIELD.EMAIL}
              onChange={(e) => changeFieldValue({ name: FIELD.EMAIL, value: e })}
              placeholder={TEXT.EMAIL_PLACEHOLDER}
              type="text"
              value={values.email}
              error={errors.email}
            />
          </Field>
          <Field label={TEXT.PASSWORD_LABEL} className="login__input">
            <Input
              name={FIELD.PASSWORD}
              onChange={(e) => changeFieldValue({ name: FIELD.PASSWORD, value: e })}
              placeholder={TEXT.PASSWORD_PLACEHOLDER}
              type="password"
              value={values.password}
              error={errors.password}
            />
          </Field>
          <div className="login__button">
            <Button type="submit" variant={ButtonVariant.PRIMARY}>
              {TEXT.CTA}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
