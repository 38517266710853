const toTens = (value: number) => value.toString().padStart(2, "0");

const getTwoLastDigits = (value: number) => value.toString().slice(-2);

const formatDate = (date: Date) =>
  `${getTwoLastDigits(date.getFullYear())}${toTens(date.getMonth() + 1)}${toTens(date.getDate())}`;

const formatHour = (date: Date) => `${toTens(date.getHours())}_${toTens(date.getMinutes())}`;

export const formatFileService = (type: string, filter: string, date: Date): string =>
  `${type}-${filter}-${formatDate(date)}-${formatHour(date)}`;
