import { Event, EventBus, EventHandler } from "@domain/shared/EventBus";

export class InMemoryEventBus implements EventBus {
  subscribersMap = new Map<string, EventHandler[]>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  publish<E extends Event<any>>(event: E): void {
    const eventHandlers = this.subscribersMap.get(event.name) ?? [];

    eventHandlers.forEach((eventHandler) => {
      eventHandler(event);
    });
  }

  subscribe(eventName: string, eventHandler: EventHandler): () => void {
    const eventHandlers = this.subscribersMap.get(eventName) ?? [];

    this.subscribersMap.set(eventName, [...eventHandlers, eventHandler]);

    return () => {
      this.subscribersMap.set(
        eventName,
        this.subscribersMap.get(eventName)?.filter((handler) => handler !== eventHandler) ?? [],
      );
    };
  }
}
