import { Checkbox, CheckboxState, Field, Error } from "@lookiero/react-ui-kit";
import React from "react";

import "./CheckboxGroup.css";

type CheckboxGroupProps = {
  checkboxList: Array<{ name: string; label: string }>;
  groupLabel: string;
  groupValues: Record<string, boolean>;
  error: string;
  touched: boolean;
  disabled?: boolean;
  onFieldChange: ({ name, value }: { name: string; value: boolean }) => void;
  onGroupChange: (e: boolean) => void;
};
const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  groupLabel,
  checkboxList,
  groupValues,
  error,
  touched,
  onFieldChange,
  onGroupChange,
  disabled,
}) => {
  const totalItems = checkboxList.length;
  const checks = Object.values(groupValues).filter(Boolean).length;

  const groupValue =
    checks === 0 ? CheckboxState.DEFAULT : checks === totalItems ? CheckboxState.CHECKED : CheckboxState.INDETERMINATE;

  const handleGroupChange = (value: boolean): void => {
    onGroupChange(value);
  };

  const handleFieldChange = (value: boolean, name: string): void => {
    onFieldChange({ name, value });
  };

  return (
    <Field label={groupLabel}>
      <Checkbox label="Select All" state={groupValue} onChange={handleGroupChange} disabled={disabled} />
      <div className="checkbox-group">
        {checkboxList.map(({ name, label }) =>
          React.cloneElement(
            <Checkbox
              label={label}
              key={name}
              onChange={(value) => handleFieldChange(value, name)}
              state={groupValues[name] ? CheckboxState.CHECKED : CheckboxState.DEFAULT}
              disabled={disabled}
            />,
          ),
        )}
      </div>
      {touched && error ? <Error error={error} /> : null}
    </Field>
  );
};

export default CheckboxGroup;
