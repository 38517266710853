import { MessageErrorEvent } from "@core/shared/MessageErrorEvent";
import { AuthStorageRepository } from "@domain/auth";
import { EventBus } from "@domain/shared/EventBus";

import { Logout } from "../Logout";

export const Unauthorized = "UNAUTHORIZED";

export const unauthorized = (eventBus: EventBus, AuthStorageRepository: AuthStorageRepository) => (): void => {
  eventBus.publish(MessageErrorEvent.create({ message: Unauthorized }));

  Logout(AuthStorageRepository, eventBus)();
};
