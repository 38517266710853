import React from "react";
import "./Stack.css";

type FlexAs = "nav" | "main" | "section" | "header" | "footer" | "aside" | "article" | "div";
type FlexDirection = "row" | "rowReverse" | "column" | "columnReverse";
type Spacing = "xxs" | "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl" | "none";
type FlexJustifyContent = "flexStart" | "flexEnd" | "center";
type FlexAlignItems = "stretch" | "flexStart" | "flexEnd" | "center";
type FlexAlignContent = "stretch" | "flexStart" | "flexEnd" | "center" | "spaceBetween" | "spaceAround";

const flexDirection: Record<FlexDirection, string> = {
  row: "flex-direction-row",
  rowReverse: "flex-direction-row-rev",
  column: "flex-direction-column",
  columnReverse: "flex-direction-column-rev",
};

const flexJustifyContent: Record<FlexJustifyContent, string> = {
  flexStart: "flex-justify-content-start",
  flexEnd: "flex-justify-content-end",
  center: "flex-justify-content-center",
};

const flexAlignItems: Record<FlexAlignItems, string> = {
  stretch: "flex-align-items-default",
  flexStart: "flex-align-items-start",
  flexEnd: "flex-align-items-end",
  center: "flex-align-items-center",
};

const flexAlignContent: Record<FlexAlignContent, string> = {
  stretch: "flex-align-content-default",
  flexStart: "flex-align-content-start",
  flexEnd: "flex-align-content-end",
  center: "flex-align-content-center",
  spaceBetween: "flex-align-content-between",
  spaceAround: "flex-align-content-around",
};

const spacing: Record<Spacing, string> = {
  xxs: "spacing-xxs",
  xs: "spacing-xs",
  s: "spacing-s",
  m: "spacing-m",
  l: "spacing-l",
  xl: "spacing-xl",
  xxl: "spacing-xxl",
  xxxl: "spacing-xxxl",
  none: "spacing-none",
};

const getRecord =
  <T,>(other: T) =>
  <K extends string | number | symbol>(record: Record<K, T>) =>
  (key: K | undefined) =>
    key ? record[key] : other;

const flexDefault = getRecord("");
const applyFlexDirection = flexDefault(flexDirection);
const applyFlexJustifyContentn = flexDefault(flexJustifyContent);
const applyFlexAlignItems = flexDefault(flexAlignItems);
const applyFlexAlignContent = flexDefault(flexAlignContent);
const applySpacing = flexDefault(spacing);

export type FlexProps = {
  as?: FlexAs;
  flexDirection?: FlexDirection;
  justifyContent?: FlexJustifyContent;
  alignItems?: FlexAlignItems;
  alignContent?: FlexAlignContent;
  spacing?: Spacing;
  className?: string;
};

interface Overload {
  (
    props: {
      as: "nav" | "main" | "section" | "header" | "footer" | "aside" | "article";
    } & React.HTMLAttributes<HTMLElement> &
      FlexProps,
  ): JSX.Element;
  (props: { as: "div" } & React.HTMLAttributes<HTMLDivElement> & FlexProps): JSX.Element;
  (props: React.HTMLAttributes<HTMLDivElement> & FlexProps): JSX.Element;
}

export const Stack: Overload = ({
  as: Element = "div",
  children,
  flexDirection = "row",
  justifyContent,
  alignItems,
  alignContent,
  spacing,
  className = "",
  ...props
}) => {
  const composedClassName = [
    "flex",
    applyFlexDirection(flexDirection),
    applyFlexJustifyContentn(justifyContent),
    applyFlexAlignItems(alignItems),
    applyFlexAlignContent(alignContent),
    applySpacing(spacing),
    className,
  ].join(" ");

  return (
    <Element className={composedClassName} {...props}>
      {children}
    </Element>
  );
};
