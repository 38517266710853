import { valueDate } from "../shared/Date";
import { valuePercentage } from "../shared/Percentage";
import { Device, DiscountAmounts, Promocode, Service } from "./Promocode";
import { FrequencyObject, toFrequencyList } from "./PromocodeFrequency";
import { valuePromocodeSites } from "./PromocodeSites";

export type Bulk = {
  total: number;
  prefix: string;
  length: number;
  promocodeInfo: Omit<Promocode, "name">;
};

export type PrimitiveBulk = {
  key?: number;
  total: number;
  prefix: string;
  length: number;
  description: string;
  discountAmounts: DiscountAmounts;
  discountPct: number;
  discountServicePct: number;
  startDate?: Date;
  endDate?: Date;
  sites: { [x: string]: boolean };
  addGift: boolean;
  isActive: boolean;
  applicableOrders?: number;
  totalUses?: number;
  firstOrderOnly: boolean;
  frequency?: FrequencyObject;
  devices?: Device[]; // TODO
  services: Service[];
};

export const fromPrimitive = ({
  total,
  length,
  prefix,
  description,
  discountAmounts,
  discountPct,
  discountServicePct,
  startDate,
  endDate,
  sites,
  addGift,
  isActive,
  applicableOrders,
  totalUses,
  firstOrderOnly,
  frequency,
  services,
}: PrimitiveBulk): Bulk => ({
  total,
  length,
  prefix,
  promocodeInfo: {
    description,
    discountAmounts,
    discountPct: valuePercentage(discountPct),
    discountServicePct: valuePercentage(discountServicePct),
    startDate: startDate ? valueDate(startDate) : undefined,
    endDate: endDate ? valueDate(endDate) : undefined,
    sites: valuePromocodeSites(sites),
    addGift,
    isActive,
    applicableOrders,
    totalUses,
    firstOrderOnly,
    frequency: frequency ? toFrequencyList(frequency) : undefined,
    services: services,
  },
});
