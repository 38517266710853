import { useMemo } from "react";
import { useHistory } from "./bridges";
import { RouteType } from "./Routes.config";
const DEFAULT_STATE = { from: undefined };

type UseNavigator = {
  goTo: (route: RouteType, params?: { [k: string]: string }, state?: { from?: string }) => void;
};
export const useNavigator = (): UseNavigator => {
  const history = useHistory();

  return useMemo(
    () => ({
      goTo: (route: RouteType, params = {}, state = DEFAULT_STATE) =>
        history.push({
          pathname: Object.keys(params).reduce((path, param) => path.replace(`:${param}`, params[param]), route.path),
          state,
        }),
    }),
    [history],
  );
};
